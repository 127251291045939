import { Icon, Text } from "app/components";

import styled from "styled-components";

const BackButton = ({
  onClick,
  background,
  color,
  text = "Back",
  width,
  margin,
  fontSize = 18,
  fontWeight = 500,
  iconSize = 20,
}) => {
  return (
    <BackRow onClick={onClick} margin={margin} width={width}>
      <IconWrapper background={background}>
        <Icon
          data={{
            icon: "FiArrowLeft",
            color: color || "var(--light-grey)",
            hover: true,
            size: iconSize,
          }}
        />
      </IconWrapper>
      <Text
        data={{
          text,
          fontSize,
          fontWeight,
          cursor: "pointer",
          color: color || "var(--light-grey)",
        }}
      />
    </BackRow>
  );
};

export default BackButton;

const BackRow = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  margin: ${(p) => p.margin || "0px"};
  width: ${(p) => p.width || "140px"};
  &:hover {
    opacity: 0.8;
  }
`;

const IconWrapper = styled.div`
  background: var(--card-background);
  border-radius: 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
