import {
  Breadcrumb,
  Button,
  FullScreenLoader,
  Icon,
  MarkdownEditor,
  Row,
  Spinner,
} from "app/components";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { EmptyStateCard } from "app/components/EmptyStateCard";
import PublicHeader from "app/public/PublicHeader";
import { ToolContainer } from "app/tool/ToolWrapper";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { rUser } from "app/utils/recoil";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useFetchTool } from "app/utils/useTool";
import { useRecoilValue } from "recoil";

const SessionDetails = () => {
  const { id } = useParams();

  const [session, setSession] = useState(null);

  const [expandedCardIndexes, setExpandedCardIndexes] = useState([]);

  const [isFetching, setIsFetching] = useState(true);

  useFetchTool();

  const cards = get(session, "cards", []);

  const user = useRecoilValue(rUser);

  let width = 3;
  if (window.innerWidth < 1000) {
    width = 2;
  }
  if (window.innerWidth < 700) {
    width = 1;
  }

  useEffect(() => {
    if (user) {
      setIsFetching(true);
      apiRequest.get(`/session_details/?id=${id}`).then((res) => {
        const sessionData = res.data;
        setSession(sessionData);
        setIsFetching(false);
      });
    }
  }, [user]);

  const navigate = useNavigate();

  if (isFetching) {
    return <FullScreenLoader />;
  }

  return (
    <ToolContainer>
      <PublicHeader />
      <Body>
        <Breadcrumb
          fontStyle="headingLg"
          margin="0px 0px 25px 0px"
          items={[
            { text: "History", onClick: () => navigate("/history") },
            { text: get(session, "tool_name"), onClick: null },
          ]}
        />

        {!user && (
          <EmptyStateCard
            title="Register to save and view your results"
            subtitle="Create an account to save your results and access them later"
            buttonText="Sign Up For Free"
            icon="FiUser"
            onClick={() => navigate("/login")}
          />
        )}

        {user && (
          <>
            {isFetching ? (
              <Spinner color="var(--text-color)" />
            ) : (
              <Cards>
                {cards.map((card, i) => {
                  const displayValue = card.value;

                  let columnSpan = card.columnSpan;
                  if (window.innerWidth < 1000) {
                    columnSpan = 2;
                  }
                  if (window.innerWidth < 700) {
                    columnSpan = 1;
                  }

                  return (
                    <Card
                      key={i}
                      columnSpan={columnSpan}
                      rowSpan={card.rowSpan}
                      onClick={() =>
                        setExpandedCardIndexes(
                          expandedCardIndexes.includes(i)
                            ? expandedCardIndexes.filter((index) => index !== i)
                            : [...expandedCardIndexes, i]
                        )
                      }
                    >
                      <CardHeader>
                        <Row gap="10px" alignItems="center">
                          <Icon
                            data={{
                              icon: "FiFileText",
                              color: "var(--text-color)",
                              margin: "2px 0 0 0",
                            }}
                          />
                          <Label>{card.label}</Label>
                        </Row>
                        <Button
                          data={{
                            text: "Copy",
                            icon: "FiCopy",
                            type: "basic",
                            onClick: () => {
                              navigator.clipboard.writeText(displayValue);
                              successNotification("Copied to clipboard");
                            },
                          }}
                        />
                      </CardHeader>
                      <CardBody expanded={expandedCardIndexes.includes(i)}>
                        <MarkdownEditor
                          data={{ value: displayValue, disabled: true }}
                        />
                        {/* {!expandedCardIndexes.includes(i) && (
                    <Overlay>View full content</Overlay>
                  )} */}
                      </CardBody>
                    </Card>
                  );
                })}
              </Cards>
            )}
          </>
        )}
      </Body>
    </ToolContainer>
  );
};

export default SessionDetails;

const CardHeader = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  padding: 20px 10px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CardBody = styled.div`
  padding: 5px 20px 20px 20px;
  max-height: ${({ expanded }) => (expanded ? "auto" : "300px")};
  overflow: hidden;
`;

const Label = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: var(--text-color);
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ width }) => width}, 1fr);
  gap: 30px;
`;

const Card = styled.div`
  position: relative;
  border: 1px solid var(--divider);
  border-radius: 10px;
  background: var(--input-background);
  grid-column: span ${({ columnSpan }) => columnSpan || 1};
  grid-row: span ${({ rowSpan }) => rowSpan || 1};
  cursor: pointer;
`;

const Body = styled.div`
  padding: 30px;
  height: calc(100vh - 70px);
  overflow: auto;
`;
