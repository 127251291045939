import { Button, Row } from "app/components";

import ToolSidebar from "./components/ToolSidebar";
import { get } from "lodash";
import styled from "styled-components";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ToolWrapper = ({
  children,
  buttons = [],
  handleBack,
  activeIndex,
  alignItems = "center",
  setCurrentStepIndex,
  maxWidth = null,
  fullWidth = false,
  previewWidth = null,
  hideTopPadding = false,
}) => {
  const location = useLocation();
  const pathname = get(location, "pathname", "");
  const isAdminEditor = pathname.includes("edit");

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, [activeIndex]);

  // This is annoying - basically need to rewrite the whole app
  if (isAdminEditor) {
    return (
      <ContentWrapper
        previewWidth={previewWidth}
        alignItems={alignItems}
        hideTopPadding={hideTopPadding}
      >
        <Content
          previewWidth={previewWidth}
          maxWidth={maxWidth}
          fullWidth={fullWidth}
        >
          {children}
        </Content>
      </ContentWrapper>
    );
  }

  return (
    <ToolContainer>
      <Row gap="0px">
        <ToolSidebar
          activeIndex={activeIndex}
          setCurrentStepIndex={setCurrentStepIndex}
        />
        <Body>
          <ContentWrapper
            previewWidth={previewWidth}
            alignItems={alignItems}
            hideTopPadding={hideTopPadding}
          >
            <Content
              previewWidth={previewWidth}
              maxWidth={maxWidth}
              fullWidth={fullWidth}
            >
              {children}
            </Content>
          </ContentWrapper>
          <Footer buttons={buttons} handleBack={handleBack} />
        </Body>
      </Row>
    </ToolContainer>
  );
};

const Footer = ({ buttons = [], handleBack }) => {
  const isMobile = window.innerWidth < 800;

  return (
    <FooterContainer>
      {handleBack ? (
        <Button
          data={{
            text: isMobile ? null : "Back",
            onClick: handleBack,
            size: "large",
            type: "basic",
            icon: "FiArrowLeft",
          }}
        />
      ) : (
        <div></div>
      )}
      <Row gap="15px">
        {buttons.map((b) => (
          <Button data={b} />
        ))}
      </Row>
    </FooterContainer>
  );
};

export default ToolWrapper;

const ContentWrapper = styled.div`
  width: 100%;
  padding: ${(p) => (p.previewWidth < 700 ? "30px" : "50px")};
  ${(p) => p.hideTopPadding && "padding-top: 10px;"}
  display: flex;
  flex-direction: column;
  height: calc(100vh - 74px);
  overflow-y: auto;
  align-items: flex-start;
`;

const Content = styled.div`
  ${(p) => p.fullWidth && "width: 100%;"}
  ${(p) =>
    !p.fullWidth &&
    `
    margin: 0 auto;
    max-width: ${p.maxWidth || "700px"};
    min-width: ${p.previewWidth < 700 ? "100%" : "550px"};
    `}
`;

const FooterContainer = styled.div`
  width: 100%;
  min-height: 73px;
  max-height: 73px;
  border-top: 1px solid var(--input-background);
  background: var(--background);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 15px 20px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--dark-background);
`;

export const ToolContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background);
`;

const Logo = styled.img`
  width: auto;
  height: 40px;
  max-width: 130px;
  object-fit: contain;
  cursor: pointer;
`;

const HeaderRow = styled.div`
  display: flex;
  padding: 15px 30px 13px 30px;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  border-bottom: 1px solid var(--card-background);
`;
