import { Icon, PaginationWrapper, Row, SimpleDropdown } from "app/components";

import { colors } from "app/utils/theme";
import { get } from "lodash";
import { resizeImage } from "app/utils/utils";
import styled from "styled-components";

const CardGrid = ({
  items,
  itemsPerPage = 8,
  noResultsName,
  hasImage = false,
}) => {
  return (
    <PaginationWrapper
      itemsPerPage={itemsPerPage}
      items={items}
      isFetching={false}
      noResultsName={noResultsName}
      padding="16px 0 0 0"
      noResultsPadding="0px"
      hideBorder
    >
      {(paginatedItems) => {
        return (
          <div>
            <Grid count={paginatedItems.length}>
              {paginatedItems.map((p, i) => (
                <Card onClick={p.onClick} key={i}>
                  {hasImage && (
                    <>
                      {p.image ? (
                        <Image
                          src={resizeImage({
                            url: p.image,
                            height: 500,
                            forceJpg: true,
                          })}
                        />
                      ) : (
                        <FakeImage>
                          <Icon
                            data={{
                              icon: "FiImage",
                              size: 40,
                              color: "var(--light-grey)",
                              hover: true,
                            }}
                          />
                        </FakeImage>
                      )}
                    </>
                  )}

                  {p.progress_percent !== undefined && (
                    <Row>
                      <Progress progress_percent={p.progress_percent} />
                      <Progress
                        incomplete
                        progress_percent={100 - p.progress_percent}
                      />
                    </Row>
                  )}

                  <Row justifyContent="space-between">
                    <div>
                      <CardLabel>{p.title}</CardLabel>
                      {p.description && <CardText>{p.description}</CardText>}
                    </div>
                    {get(p, "options", []).length > 0 && (
                      <SimpleDropdown
                        data={{
                          options: get(p, "options", []),
                          icon: {
                            icon: "FiMoreHorizontal",
                            size: 25,
                            hover: true,
                            color: colors.grey3,
                          },
                        }}
                      />
                    )}
                  </Row>
                </Card>
              ))}
            </Grid>
          </div>
        );
      }}
    </PaginationWrapper>
  );
};

export default CardGrid;

const Progress = styled.div`
  background: ${(p) => (p.incomplete ? "var(--divider)" : "var(--primary)")};
  height: 5px;
  width: ${(p) => p.progress_percent}%;
  border-radius: 2px;
`;

const CardLabel = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: var(--text-color);
`;

const CardText = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: var(--light-grey);
  margin-top: 4px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 20px;
  border-radius: 12px;
  &:hover {
    filter: brightness(0.98);
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 20px;
  grid-row-gap: 35px;
  ${(p) =>
    p.count && p.count < 4 && "grid-template-columns: 300px 300px 300px;"}
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
`;

const Image = styled.img`
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border: 1px solid var(--divider);
  border-radius: 10px;
`;

const FakeImage = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  background: var(--divider);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
