import { Button, Icon, Text } from ".";
import { useRef, useState } from "react";

import { File } from "./LargeDocumentUpload";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";
import { uploadFiles } from "app/utils/documentParsing";

const RelativeContainer = styled.div`
  position: relative;
`;

const DocumentUpload = ({ data }) => {
  const [isFetching, setIsFetching] = useState(false);

  const value = get(data, "value");

  const fileLabel = get(value, "label");
  const fileId = get(value, "id");

  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    setIsFetching(true);

    const uploadedFiles = await uploadFiles([file]);

    const fileObj = uploadedFiles[0];

    setIsFetching(false);

    data.onChange(fileObj);
  };

  const handleRemoveFile = () => {
    data.onChange(null);
  };

  return (
    <RelativeContainer>
      {!fileId ? (
        <Button
          data={{
            text: data.text || "Select File",
            size: data.size || "small",
            type: data.buttonType || "basic",
            icon: data.icon || "FiUploadCloud",
            isFetching,
            onClick: () => document.getElementById("file-input").click(),
          }}
        />
      ) : (
        <File>
          <Icon
            data={{ icon: "FiPaperclip", color: colors.primary, size: 18 }}
          />
          <Text
            data={{
              text: fileLabel,
              color: "var(--text-color)",
              fontSize: 15,
              fontWeight: 500,
            }}
          />
          <Icon
            data={{
              icon: "FiX",
              color: colors.grey3,
              size: 18,
              onClick: handleRemoveFile,
              hover: true,
            }}
          />
        </File>

        // <>
        //   <Text
        //     data={{
        //       text: data.value,
        //       margin: "0 0 5px 0",
        //       color: data.color || colors.grey3,
        //       fontStyle: "bodySm",
        //     }}
        //   />
        //   <Button
        //     data={{
        //       text: "Remove",
        //       size: "small",
        //       type: "basic",
        //       icon: "FiX",
        //       onClick: handleRemoveFile,
        //     }}
        //   />
        // </>
      )}
      <input
        id="file-input"
        type="file"
        accept=".pdf, .docx, .txt"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </RelativeContainer>
  );
};

export default DocumentUpload;
