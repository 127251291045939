import React, { useState } from "react";

import { Icon } from "app/components";
import { get } from "lodash";
import { getPixels } from "app/utils/utils";
import styled from "styled-components";

const CarouselContainer = styled.div`
  position: relative;
  width: ${({ width }) => getPixels(width)};
  height: ${({ height }) => getPixels(height)};
  overflow: hidden;
  border-radius: 10px;
  ${(p) => p.previewWidth < 800 && `max-width: ${p.previewWidth - 60}px`}
`;

const ImagesWrapper = styled.div`
  display: flex;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  transform: translateX(-${({ currentIndex }) => currentIndex * 100}%);
`;

const ImageContainer = styled.div`
  width: 100%;
  flex-shrink: 0;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: ${({ objectFit }) => objectFit || "contain"};
  border-radius: 10px;
  border: 1px solid var(--divider);
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: ${({ show }) => (show ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  height: 50px;
  padding-top: 2px;
  &:hover {
    background: rgba(255, 255, 255, 0.8);
  }
`;

const LeftButton = styled(Button)`
  left: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const RightButton = styled(Button)`
  right: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const Carousel = ({ data, previewWidth }) => {
  const images = get(data, "carousel_images", []);

  // TODO - Make the mobile resizing more dynamic
  const height = get(data, "height", 400);
  const width = get(data, "width", 600);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [hovered, setHovered] = useState(false);

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <CarouselContainer
      width={width}
      height={height}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      previewWidth={previewWidth}
    >
      <ImagesWrapper currentIndex={currentIndex}>
        {images.map((image, index) => (
          <ImageContainer key={index}>
            <Image
              src={image.image}
              alt={`Image ${index + 1}`}
              objectFit={get(data, "objectFit")}
            />
          </ImageContainer>
        ))}
      </ImagesWrapper>
      {images.length > 1 && (
        <>
          <LeftButton onClick={handlePrev} show={hovered && currentIndex !== 0}>
            <Icon data={{ icon: "FiArrowLeft", hover: true }} />
          </LeftButton>
          <RightButton
            onClick={handleNext}
            show={hovered && currentIndex !== images.length - 1}
          >
            <Icon data={{ icon: "FiArrowRight", hover: true }} />
          </RightButton>
        </>
      )}
    </CarouselContainer>
  );
};

export default Carousel;
