import { commonColors, otherColors } from "./ColorSelect";

import { Icon } from ".";
import { get } from "lodash";
import styled from "styled-components";

const BadgeContainer = styled.div`
  color: black;
  background: ${(p) => p.background || "#969696"};
  border-radius: 12px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  width: fit-content;
  block-size: fit-content;
`;

const Badge = ({ color, value, icon, onClick }) => {
  return (
    <BadgeContainer background={color} onClick={onClick}>
      {icon && <Icon data={{ icon, size: 14, color: "white" }} />}
      {value}
    </BadgeContainer>
  );
};

export default Badge;

const colors = [...commonColors, ...otherColors];

export const getBadgeColorMap = ({ customColors, uniqueValues }) => {
  // Extract unique values from uniqueValues
  const values = Array.from(new Set(uniqueValues));

  // Map from value to color and icon
  const valueToColorIconMap = new Map();

  // Build a set of colors used in customColors
  const customColorsSet = new Set(customColors.map((c) => c.color));

  // Assign custom colors and remove them from values
  customColors.forEach(({ color, value, icon }) => {
    valueToColorIconMap.set(value, { color, icon });
    values.splice(values.indexOf(value), 1); // Remove the value from values
  });

  // Build available colors, excluding custom colors
  const availableColors = colors.filter((color) => !customColorsSet.has(color));

  // Assign colors to remaining values
  let colorIndex = 0;

  values.forEach((value) => {
    if (!valueToColorIconMap.has(value)) {
      // Use modulo to cycle back to the first color if we run out
      const color = availableColors[colorIndex % availableColors.length];
      valueToColorIconMap.set(value, { color, icon: null }); // No icon for non-custom colors
      colorIndex++;
    }
  });

  // Build final object: keys are values, values are objects with color and icon
  const colorToValueMap = {};
  valueToColorIconMap.forEach((data, value) => {
    colorToValueMap[value] = data;
  });

  return colorToValueMap;
};

export const getBadgesArray = ({ value, badgeColorMap, onClick }) => {
  const splitBadge = true;

  let array = [];

  if (value) {
    value = value.toString();
  }

  const badgeArray =
    splitBadge && value
      ? value.split(",").map((v) => v.trim())
      : value
      ? [value.trim()]
      : [];

  badgeArray.forEach((v) => {
    const color = get(badgeColorMap, [v, "color"], null);
    const icon = get(badgeColorMap, [v, "icon"], null);
    array.push({
      value: v || "No Value",
      color,
      icon,
    });
  });

  return array.map((v, i) => <Badge onClick={onClick} key={i} {...v} />);
};
