import { Column, Spinner } from ".";
import styled, { keyframes } from "styled-components";

import { colors } from "app/utils/theme";
import logo from "assets/scaale-logo-blue-white.png";

const FullScreenLoader = () => {
  return (
    <Loader>
      <Column gap="30px" alignItems="center" justifyContent="center">
        {/* <ScaaleLoader /> */}
        <Logo src={logo} />
        <Spinner color="var(--text-color)" size={40} />
      </Column>
    </Loader>
  );
};

export default FullScreenLoader;

const Logo = styled.img`
  height: 40px;
`;

const Loader = styled.div`
  background: var(--background);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Define the colors for the animation
const loaderColors = ["rgba(255,255,255,0.4)", "rgba(255,255,255,0.4)"];
// const loaderColors = ["#0F48Eb", "#6488e9"];

// Animation for changing colors
const colorChange = keyframes`
  0%, 100% { background-color: ${loaderColors[0]}; }
  50% { background-color: ${loaderColors[1]}; }
`;

// Styled component for each square
const Square = styled.div`
  width: 20px;
  height: 20px;
  margin: 2px;
  border-radius: 4px;
  background-color: ${({ isAnimated }) =>
    isAnimated ? colors[0] : "rgba(255,255,255,0.4)"};
  animation: ${({ isAnimated }) => (isAnimated ? colorChange : "none")} 1.5s
    infinite ease-in-out;
  animation-delay: ${({ delay }) => delay || 0}s;
`;

// Grid container for the 3x3 grid layout
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 4px;
`;

// Component
const ScaaleLoader = () => {
  return (
    <Grid>
      {Array.from({ length: 9 }).map((_, index) => {
        const isAnimated = [2, 4, 5, 6, 7, 8].includes(index); // Only animate highlighted squares
        const delay = isAnimated ? (index % 3) * 0.1 : 0;
        return <Square key={index} isAnimated={isAnimated} delay={delay} />;
      })}
    </Grid>
  );
};
