import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import { arrayMove, getHighest, safeArray } from "app/utils/utils";

import { Button } from "app/components";
import { Tile } from "app/adminApp/components";
import { get } from "lodash";
import { spacing } from "app/utils/theme";
import { useState } from "react";

// THIS IS A GENERIC MULTI-ADD COMPONENT ALLOWING KEYS
// TO BE PASSED IN AND CONFIGURED IN A POPUP MENU

const MultiTile = ({ data }) => {
  const { onChange } = data;

  const [newItems, setNewItems] = useState([]);

  const items = safeArray(get(data, "value", []));

  const labelSingular = data.labelSingular || "Item";

  const addItem = () => {
    const newId = getHighest(items, "id") + 1;
    let newObj = {
      ...get(data, "newObject", {}),
    };

    if (!data.noLabel) {
      newObj["label"] = `New ${labelSingular}`;
    }

    newObj["id"] = newId;

    onChange([...items, newObj]);
    setNewItems([...newItems, newId]);
  };

  const deleteItem = (index) => {
    onChange(items.filter((f, i) => i !== index));
  };

  const itemClick = (index) => {
    data.itemClick(index);
  };

  const duplicateItem = (index) => {
    onChange([...items, items[index]]);
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        gap: spacing.s4,
      }}
      dragHandleSelector=".drag-item"
      lockAxis="y"
      onDrop={(e) => {
        const { addedIndex, removedIndex } = e;
        const movedItems = arrayMove(items, removedIndex, addedIndex);
        onChange(movedItems);
      }}
    >
      {items.map((f, index) => (
        <Draggable key={f.id}>
          <Tile
            className={"drag-item"}
            label={
              data.getItemLabel
                ? data.getItemLabel({ label: f.label, index })
                : f.label
            }
            onClick={() => itemClick(index)}
            onDelete={() => deleteItem(index)}
            onDuplicate={() => duplicateItem(index)}
            accent={f.accent}
            icon={f.icon}
            iconColor={f.iconColor}
          />
        </Draggable>
      ))}
      {!data.disableAdd && (
        <Button
          data={{
            text: `Add ${labelSingular}`,
            onClick: addItem,
            size: "small",
            type: "basic",
            icon: "FiPlus",
          }}
        />
      )}
    </Container>
  );
};

export default MultiTile;
