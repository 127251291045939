import "./index.css";

import { BrowserRouter } from "react-router-dom";
import React from "react";
import { RecoilRoot } from "recoil";
import RootApp from "./app/Root";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <RecoilRoot>
    <BrowserRouter>
      <RootApp />
    </BrowserRouter>
  </RecoilRoot>
);
