import { FullScreenLoader, Row, Text } from "app/components";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";

import AdminForm from "../components/AdminForm";
import SettingsSection from "./SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { rApp } from "app/utils/recoil";
import { useRecoilState } from "recoil";

const General = () => {
  const [app, setApp] = useRecoilState(rApp);

  const [changes, setChanges] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    setIsFetching(true);
    apiRequest.get("/app_settings/").then((r) => {
      setApp(r.data);
      setIsFetching(false);
    });
  }, []);

  const save = () => {
    setIsSaving(true);

    apiRequest.post("/app_settings/", changes).then((r) => {
      const error = get(r, ["data", "error"]);

      setIsSaving(false);

      if (error) {
        errorNotification(error);

        const errorType = get(r, ["data", "error_type"]);

        if (errorType === "subdomain") {
          console.log("SUBDOMAIN ERROR");
          // TODO - handle subdomain error
        }
      } else {
        successNotification("Saved");
      }
    });
    setChanges({});
  };

  let fields = [
    {
      id: "name",
      label: "App Name",
      placeholder: "App Name",
      componentId: "Input",
      value: get(app, "name"),
    },
    {
      id: "handle",
      label: "Handle",
      placeholder: "Handle",
      hint: "Your handle is your unique url identifier",
      componentId: "Input",
      value: get(app, "handle"),
    },
  ];

  function stripNonASCII(inputStr) {
    // Replace all non-ASCII characters with an empty string
    return inputStr.replace(/[^\x00-\x7F]/g, "").replace(" ", "");
  }

  const handle = get(app, "handle");

  const appUrl = `https://scaale.co/${handle || "your-handle"}/`;

  if (isFetching) {
    return <FullScreenLoader />;
  }

  return (
    <SettingsSection
      title="General Settings"
      description="Configure your high-level app settings like name, default page and branding"
    >
      <AdminForm
        labelStyle="headingSm"
        sectionPadding="5px"
        fields={fields}
        orientation="horizontal"
        submitText={"Save Changes"}
        submit={!isEmpty(changes) && save}
        width="220px"
        isFetching={isSaving}
        onChange={(k, v) => {
          let finalVal = v;
          if (k === "subdomain") {
            finalVal = v.toLowerCase();
            finalVal = stripNonASCII(finalVal);
          }
          setChanges({ ...changes, [k]: finalVal });
          setApp({
            [k]: finalVal,
          });
        }}
      />
      <Row margin="15px 0 0 5px" justifyContent="space-between">
        <Text
          data={{
            text: "Your App URL:",
            fontStyle: "headingSm",
          }}
        />
        <Text
          data={{
            text: appUrl,
            fontStyle: "headingSm",
            color: colors.primary,
            cursor: "pointer",
            onClick: () => window.open(appUrl, "_blank"),
          }}
        />
      </Row>
    </SettingsSection>
  );
};

export default General;
