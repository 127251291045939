import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import mammoth from "mammoth";

export const localParse = (file) => {
  console.log("local parse file", file);

  const fileType = get(file, "type");

  return new Promise((resolve, reject) => {
    // TXT files
    if (fileType === "text/plain") {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        resolve(text); // Resolve the promise with the text
      };
      reader.onerror = (error) => {
        reject(error); // Reject the promise if there's an error
      };
      reader.readAsText(file, "UTF-8");
    }
    // Handle DOCX files
    else if (file.name.endsWith(".docx")) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        try {
          const result = await mammoth.extractRawText({ arrayBuffer });
          const text = result.value; // The extracted text
          resolve(text);
        } catch (error) {
          console.error("Error parsing DOCX:", error);
          reject(error);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    } else {
      resolve(null);
    }
  });
};

export const uploadFiles = async (files) => {
  const uploadPromises = files.map(async (file) => {
    const fileType = get(file, "type");

    if (fileType === "text/plain") {
      // Local parsing
      const parsedFile = await localParse(file);
      console.log("parsedFile", parsedFile);
      return {
        key: file.key,
        data: parsedFile, // Assuming localParse returns the parsed data
      };
    } else {
      // API parsing
      const formData = new FormData();

      if (file instanceof Blob || file instanceof File) {
        formData.append("file", file);
      } else {
        console.error("Invalid file object", file);
      }

      try {
        const result = await apiRequest.post("/document_parser/", formData);
        const fileObj = get(result, "data");
        return fileObj;
      } catch (error) {
        console.error("Error uploading file:", error);
        throw error; // Ensure the error is propagated
      }
    }
  });

  try {
    const fileResults = await Promise.all(uploadPromises);
    console.log("fileResults", fileResults);
    return fileResults;
  } catch (error) {
    console.error("One or more file uploads failed:", error);
    throw error; // Ensure the error is propagated
  }
};
