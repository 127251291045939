import { Button } from "app/components";
import styled from "styled-components";

export const EmptyStateCard = ({
  title,
  subtitle,
  graphic,
  buttonText,
  onClick,
  margin,
  icon,
}) => {
  return (
    <CardSection margin={margin}>
      {/* <Graphic src={graphic} /> */}
      <div>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Button
          data={{
            text: buttonText,
            size: "large",
            icon: icon || "FiPlus",
            onClick,
          }}
        />
      </div>
    </CardSection>
  );
};

const Graphic = styled.img`
  width: 280px;
  height: 240px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
  color: var(--text-color);
`;

const Subtitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0 15px 0;
  max-width: 450px;
  color: var(--dark-grey);
`;

const CardSection = styled.div`
  display: flex;
  align-items: center;
  background: var(--card-background);
  padding: 30px;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid var(--divider);
  overflow: auto;
  margin: ${(p) => p.margin || "0"};
`;
