import { Icon } from "app/components";
import { get } from "lodash";
import styled from "styled-components";
import { useState } from "react";
import useTool from "app/utils/useTool";

const CourseOutline = () => {
  const { tool } = useTool();

  const [expandedGroup, setExpandedGroup] = useState(0);

  const steps = get(tool, "steps", []);

  let groups = [];

  if (steps.length > 0) {
    steps.forEach((s, i) => {
      const groupLabel = get(s, "groupLabel", "");
      if (groupLabel) {
        groups.push({
          label: groupLabel,
          steps: [],
        });
      }

      if (groups.length > 0) {
        // Is step, push to last group in the array
        groups[groups.length - 1].steps.push({ ...s, index: i });
      }
    });
  }

  return (
    <Groups>
      {groups.map((g, i) => {
        return (
          <GroupContainer>
            <GroupHeader onClick={() => setExpandedGroup(i)}>
              <Icon
                data={{
                  icon:
                    expandedGroup === i ? "FiChevronDown" : "FiChevronRight",
                  color: "var(--text-color)",
                  size: 22,
                  hover: true,
                  margin: "2px 0 0 0",
                }}
              />
              <GroupLabel>{get(g, "label", "")}</GroupLabel>
            </GroupHeader>
            {expandedGroup === i && (
              <Items>
                {get(g, "steps", []).map((s) => {
                  let background = "transparent";
                  let icon = null;

                  return (
                    <StepItem key={s.id}>
                      <IconWrapper background={background}>{icon}</IconWrapper>
                      <StepName>{get(s, "label", "")}</StepName>
                    </StepItem>
                  );
                })}
              </Items>
            )}
          </GroupContainer>
        );
      })}
    </Groups>
  );
};

export default CourseOutline;

const Groups = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--divider);
  border-radius: 10px;
  background: var(--input-background);
`;

const GroupContainer = styled.div`
  width: 100%;
`;

const GroupHeader = styled.div`
  padding: 15px 15px 15px 13px;
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid var(--button-border);
  cursor: pointer;
  &:hover {
    background: var(--card-background);
  }
`;

const GroupLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color);
`;

const IconWrapper = styled.div`
  min-width: 18px;
  max-width: 18px;
  min-height: 18px;
  max-height: 18px;
  border-radius: 50%;
  background: ${(p) => p.background};
  border: 2px solid ${(p) => p.background};
  ${(p) => !p.complete && "border: 2px solid var(--light-grey);"}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepItem = styled.div`
  padding: 12px 15px 12px 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
`;

const StepName = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: var(--text-color);
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
  border-bottom: 1px solid var(--button-border);
`;
