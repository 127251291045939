import { Breadcrumb, Icon, Row } from "app/components";

import { get } from "lodash";
import logo from "assets/scaale-logo-blue-white.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useTool from "app/utils/useTool";

// This is the sidebar within the tool page
const ToolSidebar = ({ activeIndex }) => {
  const navigate = useNavigate();
  const { tool } = useTool();

  const [expandedGroup, setExpandedGroup] = useState(0);

  const toolName = get(tool, "name", "");

  const steps = get(tool, "steps", []);

  let groups = [];

  if (steps.length > 0) {
    steps.forEach((s, i) => {
      const groupLabel = get(s, "groupLabel", "");
      if (groupLabel) {
        groups.push({
          label: groupLabel,
          steps: [],
        });
      }

      if (groups.length > 0) {
        // Is step, push to last group in the array
        groups[groups.length - 1].steps.push({ ...s, index: i });
      }
    });
  }

  return (
    <SidebarContainer>
      <Logo src={logo} onClick={() => navigate("/")} />
      <SidebarHeader>
        <Breadcrumb
          fontStyle="headingMd"
          margin="0px 0px 2px 0px"
          items={[
            { text: "Tools", onClick: () => navigate("/browse") },
            { text: toolName, onClick: null },
          ]}
        />
      </SidebarHeader>

      <Groups>
        {groups.map((g, i) => {
          return (
            <GroupContainer>
              <GroupHeader onClick={() => setExpandedGroup(i)}>
                <Icon
                  data={{
                    icon:
                      expandedGroup === i ? "FiChevronDown" : "FiChevronRight",
                    color: "var(--text-color)",
                    size: 22,
                    hover: true,
                    margin: "2px 0 0 0",
                  }}
                />
                <GroupLabel>{get(g, "label", "")}</GroupLabel>
              </GroupHeader>
              {expandedGroup === i && (
                <Items>
                  {get(g, "steps", []).map((s) => {
                    const isComplete = s.index < activeIndex;
                    const isActive = s.index === activeIndex;

                    let background = "transparent";
                    let icon = null;

                    if (isActive) {
                      icon = (
                        <Icon
                          data={{
                            icon: "BsFillCircleFill",
                            color: "var(--light-grey)",
                            size: 8,
                          }}
                        />
                      );
                    } else if (isComplete) {
                      background = "#1f7f61";
                      icon = (
                        <Icon
                          data={{
                            icon: "FiCheck",
                            color: "var(--text-color)",
                            size: 14,
                          }}
                        />
                      );
                    }

                    return (
                      <StepItem
                        key={s.id}
                        // onClick={() => setCurrentStepIndex(i)}
                      >
                        <IconWrapper
                          background={background}
                          complete={isComplete}
                        >
                          {icon}
                        </IconWrapper>
                        <StepName>{get(s, "label", "")}</StepName>
                      </StepItem>
                    );
                  })}
                </Items>
              )}
            </GroupContainer>
          );
        })}
      </Groups>
    </SidebarContainer>
  );
};

export default ToolSidebar;

const Groups = styled.div`
  width: 100%;
  height: calc(100vh);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  // margin-top: 15px;
`;

const GroupContainer = styled.div`
  width: 100%;
`;

const GroupHeader = styled.div`
  padding: 15px 15px 15px 13px;
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid var(--card-background);
  cursor: pointer;
  &:hover {
    background: var(--card-background);
  }
`;

const GroupLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color);
`;

const SidebarContainer = styled.div`
  min-width: 350px;
  max-width: 350px;
  height: 100%;
  height: calc(100vh);
  border-right: 1px solid var(--card-background);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 800px) {
    display: none;
  }
`;

const SidebarHeader = styled.div`
  border-bottom: 1px solid var(--input-background);
  border-top: 1px solid var(--input-background);
  padding: 12px 15px 12px 15px;
  width: 100%;
`;

const Logo = styled.img`
  width: auto;
  object-fit: contain;
  height: 40px;
  width: 130px;
  cursor: pointer;
  margin: 15px 18px 15px 18px;
`;

const IconWrapper = styled.div`
  min-width: 18px;
  max-width: 18px;
  min-height: 18px;
  max-height: 18px;
  border-radius: 50%;
  background: ${(p) => p.background};
  border: 2px solid ${(p) => p.background};
  ${(p) => !p.complete && "border: 2px solid var(--light-grey);"}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepItem = styled.div`
  padding: 12px 15px 12px 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
`;

const StepName = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: var(--text-color);
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
  border-bottom: 1px solid var(--card-background);
`;
