import { Icon, Input, Text } from "app/components";

import { Drawer as MuiDrawer } from "@mui/material";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";

const Container = styled.div`
  width: ${(p) => p.width || "100%"};
  background: ${(p) => p.background};
  height: 100%;
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 9999;
  }
`;

const HeaderRow = styled.div`
  padding: 25px 25px 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Body = styled.div`
  width: 100%;
  padding: ${(p) => p.padding || "25px"};
`;

const Drawer = ({ data, children }) => {
  const label = get(data, "label");
  const onLabelChange = get(data, "onLabelChange");
  const description = get(data, "description");
  const hideHeader = get(data, "hideHeader", false);
  const darkMode = get(data, "darkMode", false);

  const drawerContent = (
    <Container width={data.width} background={data.background}>
      {!hideHeader && (
        <HeaderRow>
          <div>
            {label && !onLabelChange && (
              <Text
                data={{
                  text: label,
                  fontStyle: "headingLg",
                  color: darkMode && "white",
                }}
              />
            )}
            {onLabelChange && (
              <Input
                data={{
                  value: label,
                  onChange: onLabelChange,
                  border: "0px",
                  fontStyle: "headingXl",
                  padding: "0px",
                }}
              />
            )}
            {description && (
              <Text
                data={{
                  text: description,
                  fontStyle: "bodyLg",
                  margin: "5px 0 0 0",
                  color: colors.grey4,
                }}
              />
            )}
          </div>
          <Icon
            data={{
              hover: true,
              color: colors.grey3,
              size: 24,
              onClick: data.hide,
              margin: "0 10px 0 0",
              icon: "FiX",
            }}
          />
        </HeaderRow>
      )}

      <Body padding={data.padding}>{children}</Body>
    </Container>
  );

  if (window.innerWidth < 800) {
    return drawerContent;
  }

  return (
    <MuiDrawer
      open={!!data.open}
      onClose={data.hide}
      style={{ zIndex: data.zIndex || 500 }}
    >
      {drawerContent}
    </MuiDrawer>
  );
};

export default Drawer;
