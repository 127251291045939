import {
  rActiveStepIndex,
  rIsFetchingTool,
  rToolChanges,
  rTools,
} from "app/utils/recoil";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { apiRequest } from "./apiRequests";
import { get } from "lodash";
import { useEffect } from "react";

const useTool = () => {
  const { id } = useParams();
  const [tools, setTools] = useRecoilState(rTools);
  const setToolChanges = useSetRecoilState(rToolChanges);
  const tool = get(tools, id);

  const activeStepIndex = useRecoilValue(rActiveStepIndex);
  const activeStepConfig = get(tool, ["steps", activeStepIndex || 0], {});

  const setTool = (data) => {
    setToolChanges(true);
    setTools({
      ...tools,
      [id]: {
        ...tool,
        ...data,
      },
    });
  };

  return { tool, setTool, activeStepConfig };
};

export default useTool;

export const useFetchTool = () => {
  const { id } = useParams();

  const setIsFetchingTool = useSetRecoilState(rIsFetchingTool);

  const { tool, setTool } = useTool();

  const location = useLocation();
  const path = location.pathname;
  const isToolRoute = path.includes("tool"); // || path.includes("session");

  // Fetch tool config
  useEffect(() => {
    if (isToolRoute && !tool) {
      setIsFetchingTool(true);
      apiRequest.get(`/get_tool/?id=${id}`).then((res) => {
        setTool(get(res, "data", null));
        setIsFetchingTool(false);
      });
    }
  }, []);

  return { tool, setTool };
};
