import {
  cloneDeep,
  get,
  isArray,
  isInteger,
  isNaN,
  isNil,
  isString,
  startsWith,
} from "lodash";

import Cookies from "js-cookie";
import moment from "moment";

export const fieldTypeToComponentIdMap = {
  string: "Input",
  number: "Input",
  boolean: "Checkbox",
  date: "DatePicker",
  datetime: "DateTimePicker",
  time: "TimePicker",
};

export const publicRoutes = ["/", "/login", "/signup", "/browse"];

export const fullWidthFormFields = [
  "ImageUpload",
  "TextArea",
  "LargeDocumentUpload",
  "LargeRadioGroup",
  "RadioGroup",
  "Signature",
];

export function getPreviousSteps(actions, actionId) {
  // Create a map for easy access to each action by its ID
  const actionMap = new Map(actions.map((action) => [action.id, action]));

  // Function to get all ancestors of a given action
  function getAncestors(actionId) {
    const action = actionMap.get(actionId);
    if (!action || !action.parent) {
      return [];
    }
    // Get the parent and recursively get its ancestors
    const parentAncestors = getAncestors(action.parent);
    return [action.parent, ...parentAncestors];
  }

  return getAncestors(actionId);
}

export const isValidJson = (inputJson) => {
  try {
    JSON.parse(inputJson);
    return true;
  } catch (error) {
    return false;
  }
};

export const operatorLabelMap = {
  // Equals
  equals: "Equals",
  does_not_equal: "Does Not Equal",
  contains: "Contains",
  in: "In",
  not_in: "Not In",
  does_not_contain: "Does Not Contain",
  // Less / more
  greater_than: "Greater Than",
  less_than: "Less Than",
  length_greater_than: "Length Greater Than",
  length_less_than: "Length Less Than",
  // Booleans
  is_true: "Is True (boolean)",
  is_false: "Is False (boolean)",
  exists: "Exists (has value)",
  does_not_exist: "Does Not Exist (no value)",
  // Dates
  date_equals: "Date Equals",
  date_after: "Date After",
  date_before: "Date Before",
  date_in_range: "Date In Range",
  // New type-specific operators
  string_equals_string: "String Equals String",
  array_contains_string: "Array Contains String",
  string_contains_string: "String Contains String",
  string_in_array: "String In Array",
  string_in_string: "String In String",
  string_not_in_string: "String Not In String",
  string_not_in_array: "String Not In Array",
  array_does_not_contain_string: "Array Does Not Contain String",
  string_does_not_contain_string: "String Does Not Contain String",
};

export const operators = [
  "equals",
  "does_not_equal",
  "greater_than",
  "less_than",
  "contains",
  "in",
  "not_in",
  "is_true",
  "is_false",
  "does_not_contain",
  "exists",
  "does_not_exist",

  // "length_less_than",
  // "length_greater_than",
  // "date_equals",
  // "date_after",
  // "date_before",
  // "date_in_range",

  // New type-specific operators
  // "array_contains_string",
  // "array_does_not_contain_string",
  // "string_contains_string",
  // "string_does_not_contain_string",
  // "string_in_array",
  // "string_in_string",
  // "string_not_in_array",
  // "string_not_in_string",
];

export const asyncActionTypes = ["MAKE", "WEBHOOK", "AI_REQUEST", "ZAPIER"];

export function formatNumber(num, decimals) {
  if (isNaN(decimals)) {
    return num;
  }

  const decimalsInt = parseInt(decimals, 0);

  if (!decimalsInt || decimalsInt === 0) {
    return num;
  }

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(num);
}

export const safeString = (str) => {
  if (str === null || str === undefined) {
    return "";
  }

  return str.toString();
};

export const safeToFixed = (value, decimals) => {
  if (!value) {
    const num = 0;
    return num.toFixed(decimals);
  }

  try {
    return parseFloat(value).toFixed(decimals);
  } catch (error) {
    const num = 0;
    return num.toFixed(decimals);
  }
};

export const safeArray = (obj, key = null) => {
  let potentialArray = obj;

  if (key) {
    potentialArray = get(obj, key, []);
  }

  if (isArray(potentialArray)) {
    return potentialArray;
  }

  return [];
};

export const getUniqueRoute = (routes, route) => {
  let newRoute = route;

  if (routes.includes(newRoute)) {
    let number = 1;
    newRoute = `${route}-${number}`;

    while (routes.includes(newRoute)) {
      number += 1;
      newRoute = `${route}-${number}`;
    }
  }

  return newRoute;
};

const detectInputType = (input) => {
  // Regular expression pattern to detect an email
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Regular expression pattern to detect a URL
  const urlPattern =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

  if (emailPattern.test(input)) {
    return "email";
  } else if (urlPattern.test(input)) {
    return "url";
  } else {
    return "neither";
  }
};

const ensureHttps = (url) => {
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  } else {
    return "https://" + url;
  }
};

export const getLinkOnClick = (value) => {
  const textPattern = detectInputType(value);

  if (textPattern !== "neither") {
    if (textPattern === "url") {
      return () => window.open(ensureHttps(value), "blank");
    } else {
      const emailLink = `mailto:${value}`;
      return () => window.open(emailLink, "blank");
    }
  }

  return null;
};

export const parseDateWithFormatObject = (data) => {
  const {
    value,
    formatObject = null,
    returnMoment = false,
    returnNativeDate = false,
  } = data;

  const formatType = get(formatObject, "formatType");
  const customFormat =
    formatType === "custom" ? get(formatObject, "customFormat") : null;

  let date = get(formatObject, "date");

  if (!date) {
    date = detectDateFormat(value);
  }

  const time = get(formatObject, "time");
  const showAmPm = time && get(formatObject, "showAmPm");

  let format = date;

  if (time) {
    format = `${date} ${time}`;

    if (!showAmPm) {
      // If not showing AM/PM, convert to 24 hour format
      format = format.replace("hh", "HH").replace("h", "HH");
    }
  }

  if (customFormat) {
    format = customFormat;
  }

  let dateMoment = null;

  if (moment.isMoment(value)) {
    // Check if the date input is already a moment object
    dateMoment = value;
  }

  // Check if the date input is a Date object
  else if (!dateMoment && value instanceof Date) {
    dateMoment = moment(value);
  }

  // If the date input is a string, use the provided format
  else if (!dateMoment && typeof value === "string") {
    dateMoment = moment(value, format);
  }

  if (dateMoment && !dateMoment.isValid()) {
    return "";
  }

  if (dateMoment) {
    if (returnNativeDate) {
      return dateMoment.toDate();
    }

    if (returnMoment) {
      return dateMoment;
    }

    let finalFormat = date;

    if (customFormat) {
      finalFormat = customFormat;
    } else {
      if (time) {
        finalFormat += ` ${time}`;

        if (!showAmPm) {
          // If not showing AM/PM, convert to 24 hour format
          finalFormat = finalFormat.replace("hh", "HH").replace("h", "HH");
        }
      }

      if (showAmPm) {
        finalFormat += ` a`;
      }
    }
    return dateMoment.format(finalFormat);
  }

  return dateMoment;
};

export const defaultTrue = (value) => (isNil(value) ? true : value);

export const sortItems = (items, listKey, sortingOrder, sortingKey) => {
  let newItems = [];
  sortingOrder.forEach((orderItem) => {
    const sortKeyValue = get(orderItem, sortingKey);
    const match = items.find((i) => get(i, listKey) === sortKeyValue);
    if (match) {
      newItems.push({ ...match, ...orderItem });
    }
  });

  const orderedIds = newItems.map((i) => get(i, listKey));

  items
    .filter((i) => !orderedIds.includes(get(i, listKey)))
    .forEach((item) => {
      newItems.push(item);
    });
  return newItems;
};

export const allBlocks = [
  {
    type: "CONTENT_GENERATION",
    label: "Content Generation",
    icon: "HiSparkles",
    description: "Generate content with AI based on a prompt",
  },
];

export const sortSections = (sections, sectionOrder) => {
  const orderedSections = sections
    .filter((section) => sectionOrder.includes(section))
    .sort((a, b) => sectionOrder.indexOf(a) - sectionOrder.indexOf(b));

  const undefinedSections = sections.filter(
    (section) => !sectionOrder.includes(section)
  );

  return orderedSections.concat(undefinedSections);
};

export const getDateFormatString = (format) => {
  const formatType = get(format, "formatType");
  const customFormat =
    formatType === "custom" ? get(format, "customFormat") : null;

  if (customFormat) {
    return customFormat;
  }

  const time = get(format, "time");
  const date = get(format, "date");
  const showAmPm = get(format, "showAmPm");

  let formatString = "";

  if (date && time) {
    formatString = `${date} ${time}`;
  } else if (date) {
    formatString = date;
  } else if (time) {
    formatString = time;
  }

  if (!showAmPm) {
    // If not showing AM/PM, convert to 24 hour format
    formatString = formatString.replace("hh", "HH").replace("h", "HH");
  }

  return formatString;
};

export const defaultDateFormat = "YYYY/MM/DD";

export const formatEuro = (number, decimalPlaces = 0) => {
  const decimalsInt = parseInt(decimalPlaces, 0) || 0;

  // Create a new instance of Intl.NumberFormat for the 'de-DE' locale
  const formatter = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: decimalsInt,
    maximumFractionDigits: decimalsInt,
  });

  // Format the number using the formatter
  return formatter.format(number);
};

export const euroToFloat = (euroString) => {
  // Remove all non-numeric characters except comma and period
  let onlyNumbersCommaAndPeriod = safeString(euroString).replace(
    /[^0-9,\.]/g,
    ""
  );

  // Remove periods used as thousands separators (if any remain after the above step, they are likely thousand separators)
  let withoutThousands = onlyNumbersCommaAndPeriod.replace(/\./g, "");

  // Replace comma with period to use as decimal separator
  let normalized = withoutThousands.replace(",", ".");

  if (normalized) {
    // Check if cleaned value contains a float
    if (/^-?\d+\.\d+$/.test(normalized)) {
      return parseFloat(normalized);
    }

    // Check if cleaned value contains an integer
    if (/^-?\d+$/.test(normalized)) {
      return parseInt(normalized, 10);
    }

    // If value contains numbers mixed with letters or is invalid, return 0
    return 0;
  }
  return 0;
};

export const parseNumber = (value) => {
  if (value) {
    // Remove commas, spaces, and any currency symbols
    const cleanedValue = value.toString().replace(/[,€£$¥₹%\s]/g, "");

    // Check if cleaned value contains a float
    if (/^-?\d+\.\d+$/.test(cleanedValue)) {
      return parseFloat(cleanedValue);
    }

    // Check if cleaned value contains an integer
    if (/^-?\d+$/.test(cleanedValue)) {
      return parseInt(cleanedValue, 10);
    }

    // If value contains numbers mixed with letters or is invalid, return 0
    return 0;
  }
  return 0;
};

export const safeLower = (str) => {
  if (typeof str === "string") {
    return str.toLowerCase();
  }

  if (isNil(str)) {
    return "";
  }

  return str.toString();
};

export function safeParseFloatOrInt(str) {
  const parsedFloat = parseFloat(str);
  const parsedInt = parseInt(str, 10);

  // Check if the parsedFloat is a valid number (not NaN) and the same as the parsedInt
  if (!isNaN(parsedFloat) && parsedFloat === parsedInt) {
    return parsedInt; // Return the parsed integer if it's a valid integer
  } else if (!isNaN(parsedFloat)) {
    return parsedFloat; // Return the parsed float if it's a valid float
  } else {
    return 0; // Return 0 if neither a valid float nor integer
  }
}

const getArrayFromCommaSeparatedString = (s) => {
  const split = s
    ? s
        .toString()
        .split(",")
        .map((v) => v.trim())
        .filter((s) => s !== "")
    : [];
  return split;
};

export const getUniqueValues = (array, key, splitValues = false) => {
  if (!isArray(array)) {
    return [];
  }

  if (splitValues) {
    // Split each array item (a comma-separated string) into a new flat list
    const finalArray = array
      .reduce((result, item) => {
        const beforeSplit = safeString(get(item, key, "")).toString();
        const splitItem = beforeSplit.split(",");
        return result.concat(splitItem);
      }, [])
      .map((v) => v.trim());

    const uniqueVals = finalArray.reduce((result, item) => {
      if (result.indexOf(item) === -1) {
        result.push(item);
      }
      return result;
    }, []);
    return uniqueVals;
  }

  let uniqueValues = array.reduce((result, item) => {
    if (result.indexOf(item[key]) === -1) {
      result.push(get(item, key));
    }
    return result;
  }, []);

  return uniqueValues;
};

// TODO - Figure out how to ensure the app date format is passed in.
// Right now it could still work as long as the format passed in already has the app format in it
export const passesCondition = (data) => {
  const {
    value1 = "",
    value2 = "",
    operator = "contains",
    value1DateFormat = null,
    // value2DateFormat = null,
  } = data;

  let meetsCondition = false;

  // EXISTS
  if (operator === "exists") {
    meetsCondition = !isNil(value1) && value1 !== "";
  }

  // DOES NOT EXIST
  else if (operator === "does_not_exist") {
    meetsCondition = isNil(value1) || value1 === "";
  }

  // IS TRUE
  else if (operator === "is_true") {
    meetsCondition = parseBoolean(value1);
  }

  // IS FALSE
  else if (operator === "is_false") {
    meetsCondition = !parseBoolean(value1);
  }

  // EQUALS
  else if (operator === "equals") {
    const value1Array = getArrayFromCommaSeparatedString(value1);
    const value2Array = getArrayFromCommaSeparatedString(value2);

    // COMPARE 2 COMMA-SEPARATED LISTS
    let matches = 0;
    value1Array.forEach((v1) => {
      value2Array.forEach((v2) => {
        if (safeLower(v1) === safeLower(v2)) {
          matches += 1;
        }
      });
    });

    meetsCondition = matches > 0;
  }

  // DOES NOT EQUAL
  else if (operator === "does_not_equal") {
    meetsCondition = safeLower(value1) !== safeLower(value2);
  }

  // STRING EQUALS STRING
  else if (operator === "string_equals_string") {
    meetsCondition = safeLower(value1) === safeLower(value2);
  }

  // IN
  else if (operator === "in" || operator === "string_in_array") {
    const value1Array = getArrayFromCommaSeparatedString(value1);
    const value2Array = getArrayFromCommaSeparatedString(value2);

    if (value1Array.length === 0 || value2Array.length === 0) {
      return false;
    }

    meetsCondition = value1Array.some((val1) =>
      value2Array.includes(safeLower(val1))
    );
  }

  // STRING IN ARRAY
  else if (operator === "string_in_string") {
    meetsCondition = safeLower(value2).includes(safeLower(value1));
  }

  // STRING NOT IN STRING
  else if (operator === "string_not_in_string") {
    meetsCondition = !safeLower(value2).includes(safeLower(value1));
  }

  // NOT IN
  else if (operator === "string_not_in_array") {
    const value1Array = getArrayFromCommaSeparatedString(value1);
    const value2Array = getArrayFromCommaSeparatedString(value2);

    if (value1Array.length === 0 || value2Array.length === 0) {
      return false;
    }

    meetsCondition = !value1Array.some((val1) =>
      value2Array.includes(safeLower(val1))
    );
  }

  // CONTAINS
  else if (operator === "contains" || operator === "array_contains_string") {
    if (value1.includes(",") || value2.includes(",")) {
      const value1Array = getArrayFromCommaSeparatedString(value1);
      const value2Array = getArrayFromCommaSeparatedString(value2);

      if (value1Array.length === 0 || value2Array.length === 0) {
        return false;
      }

      // Check if any element in value2Array exists in value1Array
      meetsCondition = value2Array.some((val2) =>
        value1Array.includes(safeLower(val2))
      );
    } else {
      meetsCondition = safeLower(value1).includes(safeLower(value2));
    }
  }

  // STRING CONTAINS STRING
  else if (operator === "string_contains_string") {
    meetsCondition = safeLower(value1).includes(safeLower(value2));
  }

  // DOES NOT CONTAIN
  else if (
    operator === "does_not_contain" ||
    operator === "string_does_not_contain_string"
  ) {
    meetsCondition = !safeLower(value1).includes(safeLower(value2));
  }

  // ARRAY DOES NOT CONTAIN STRING
  else if (operator === "array_does_not_contain_string") {
    const value1Array = getArrayFromCommaSeparatedString(value1);
    const value2Array = getArrayFromCommaSeparatedString(value2);

    if (value1Array.length === 0 || value2Array.length === 0) {
      return false;
    }

    meetsCondition = !value2Array.some((val2) =>
      value1Array.includes(safeLower(val2))
    );
  }

  // LENGTH GREATER THAN
  else if (operator === "length_greater_than") {
    meetsCondition = safeLower(value1).length > Number(value2);
  }

  // LENGTH LESS THAN
  else if (operator === "length_less_than") {
    meetsCondition = safeLower(value1).length < Number(value2);
  }

  // GREATER THAN
  else if (operator === "greater_than") {
    meetsCondition = Number(value1) > Number(value2);
  }

  // LESS THAN
  else if (operator === "less_than") {
    meetsCondition = Number(value1) < Number(value2);
  }

  // DATE EQUALS
  else if (operator === "date_equals") {
    const date1 = parseDateWithFormatObject({
      value: value1,
      formatObject: value1DateFormat,
      returnMoment: true,
    });

    // Hard-coding the match the date format of the datepicker component
    const date2 = parseDateWithFormatObject({
      value: value2,
      formatObject: value1DateFormat,
      returnMoment: true,
    });

    if (date1 && date2) {
      meetsCondition = date1.isSame(date2, "day");
    } else {
      meetsCondition = false;
    }
  }

  // DATE AFTER
  else if (operator === "date_after") {
    const date1 = parseDateWithFormatObject({
      value: value1,
      formatObject: value1DateFormat,
      returnMoment: true,
    });
    const date2 = parseDateWithFormatObject({
      value: value2,
      formatObject: value1DateFormat,
      returnMoment: true,
    });

    if (date1 && date2) {
      meetsCondition = date1.isAfter(date2);
    } else {
      meetsCondition = false;
    }
  }

  // DATE BEFORE
  else if (operator === "date_before") {
    const date1 = parseDateWithFormatObject({
      value: value1,
      formatObject: value1DateFormat,
      returnMoment: true,
    });
    const date2 = parseDateWithFormatObject({
      value: value2,
      formatObject: value1DateFormat,
      returnMoment: true,
    });

    if (date1 && date2) {
      meetsCondition = date1.isBefore(date2);
    } else {
      meetsCondition = false;
    }
  }

  // DATE RANGE
  else if (operator === "date_in_range") {
    const date1 = parseDateWithFormatObject({
      value: value1,
      formatObject: value1DateFormat,
      returnMoment: true,
    });
    const [start, end] = value2.split("|");

    if (start && end) {
      const rangeStart = parseDateWithFormatObject({
        value: start,
        formatObject: value1DateFormat,
        returnMoment: true,
      });

      const rangeEnd = parseDateWithFormatObject({
        value: end,
        formatObject: value1DateFormat,
        returnMoment: true,
      });

      if (date1 && rangeStart && rangeEnd) {
        meetsCondition = date1.isBetween(rangeStart, rangeEnd, "day", "[]");
      } else {
        meetsCondition = true;
      }
    }
  }

  // NUMBER IN RANGE
  else if (operator === "number_in_range") {
    const [min, max] = value2.split("|");

    if (!min && !max) {
      return true;
    } else if (min && max) {
      meetsCondition =
        Number(value1) >= Number(min) && Number(value1) <= Number(max);
    } else if (min) {
      meetsCondition = Number(value1) >= Number(min);
    } else if (max) {
      meetsCondition = Number(value1) <= Number(max);
    }
  }

  return meetsCondition;
};

export const dateFormatOptions = [
  {
    label: "YYYY/MM/DD",
    value: "YYYY/MM/DD",
  },
  {
    label: "YYYY/DD/MM",
    value: "YYYY/DD/MM",
  },
  {
    label: "DD/MM/YYYY",
    value: "DD/MM/YYYY",
  },
  {
    label: "MM/DD/YYYY",
    value: "MM/DD/YYYY",
  },
  {
    label: "YYYY-MM-DD",
    value: "YYYY-MM-DD",
  },
  {
    label: "YYYY-DD-MM",
    value: "YYYY-DD-MM",
  },
  {
    label: "DD-MM-YYYY",
    value: "DD-MM-YYYY",
  },
  {
    label: "MM-DD-YYYY",
    value: "MM-DD-YYYY",
  },
];

function detectDateFormat(input) {
  let formats = [
    "DD-MM-YYYY",
    "D-MM-YYYY",
    "DD-M-YYYY",
    "D-M-YYYY",
    "MM-DD-YYYY",
    "M-DD-YYYY",
    "MM-D-YYYY",
    "M-D-YYYY",
    "YYYY-MM-DD",
    "YYYY-M-DD",
    "YYYY-MM-D",
    "YYYY-M-D",
    "DD/MM/YYYY",
    "D/MM/YYYY",
    "DD/M/YYYY",
    "D/M/YYYY",
    "MM/DD/YYYY",
    "M/DD/YYYY",
    "MM/D/YYYY",
    "M/D/YYYY",
    "YYYY/MM/DD",
    "YYYY/M/DD",
    "YYYY/MM/D",
    "YYYY/M/D",
    "DD-MM-YY",
    "D-M-YY",
    "MM-DD-YY",
    "M-D-YY",
    "YY-MM-DD",
    "YY-M-D",
    "DD/MM/YY",
    "D/M/YY",
    "MM/DD/YY",
    "M/D/YY",
    "YY/MM/DD",
    "YY/M/D",
    "DD.MM.YYYY",
    "D.MM.YYYY",
    "DD.M.YYYY",
    "D.M.YYYY",
    "MM.DD.YYYY",
    "M.DD.YYYY",
    "MM.D.YYYY",
    "M.D.YYYY",
    "YYYY.MM.DD",
    "YYYY.M.DD",
    "YYYY.MM.D",
    "YYYY.M.D",
    "DD.MM.YY",
    "D.M.YY",
  ];
  for (let i = 0; i < formats.length; i++) {
    if (moment(input, formats[i], true).isValid()) {
      return formats[i];
    }
  }
  return "";
}

export const getHighest = (source, field = "id") => {
  let highestId = 0;

  if (!source) {
    return highestId;
  }

  safeArray(source).forEach((f) => {
    const match = get(f, field) && parseInt(get(f, field));
    if (match && match > highestId) {
      highestId = match;
    }
  });
  return highestId;
};

export const getGridStaticItems = (count, size = "300px") => {
  if (count === 1) {
    return size;
  } else if (count === 2) {
    return `${size} ${size}`;
  } else if (count === 3) {
    return `${size} ${size} ${size}`;
  }
};

export const isValidSubdomain = (subdomain) => {
  const errors = {};

  if (!subdomain) {
    errors["subdomain"] = "Please enter a valid subdomain.";
  } else if (subdomain.length < 3) {
    errors["subdomain"] = "Please enter a longer subdomain.";
  } else if (subdomain.toLowerCase().includes("frontly")) {
    errors["subdomain"] =
      "'Scaale' is a protected word and cannot be used in a subdomain.";
  } else if (!/^[a-zA-Z0-9._-]*$/.test(subdomain)) {
    // only hyphens
    errors["subdomain"] =
      "Spaces and special characters are not allowed in subdomain.";
  }

  return errors;
};

export const openToolInNewTab = ({ toolId, token = "" }) => {
  let previewPageLink = `https://www.scaale.co`;

  // Handle localhost
  if (window.location.href.includes("localhost")) {
    previewPageLink = `http://localhost:3000`;
  }

  previewPageLink += `/tool/${toolId}`;

  if (token) {
    previewPageLink += `?magic=${token}`;
  }

  // Ensure no double slashes before query parameters
  previewPageLink = previewPageLink.replace("/?magic=", "?magic=");

  window.open(previewPageLink);
};

export const isAdmin = false;

export const arrayMove = (arrayItems, removedIndex, addedIndex) => {
  const arrCopy = cloneDeep(arrayItems);
  const removedItem = arrCopy.splice(removedIndex, 1);
  arrCopy.splice(addedIndex, 0, removedItem[0]);

  return arrCopy;
};

export const addSlash = (route) => {
  if (!route) {
    return null;
  }
  if (startsWith(route, "/") || route.includes("http")) {
    return route;
  }
  return `/${route}`;
};

export const downloadAsCsv = (rows, headers = [], fileName = "data") => {
  const csvRows = [
    headers
      .map((h) => (h === "frontly_id" ? "fid" : h))
      .map((h) => `"${h.replace(/"/g, '""')}"`)
      .join(","),
    ...rows.map((row) =>
      headers
        .map((h) => {
          const rowV = get(row, h, "") || "";
          return `"${rowV.toString().replace(/"/g, '""')}"`;
        })
        .join(",")
    ),
  ];

  const csvString = csvRows.join("\n");

  const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(
    csvString
  )}`;

  const link = document.createElement("a");
  link.setAttribute("href", csvContent);
  link.setAttribute("download", `${fileName.replace(".csv", "")}.csv`);
  document.body.appendChild(link); // Required for FF (Firefox)
  link.click();
};

// export const downloadComponentAsPDF = async (componentRef) => {
//   // Capture the component
//   const canvas = await html2canvas(componentRef.current);
//   const screenshotDataURL = canvas.toDataURL("image/png");

//   // Create a new jsPDF instance and calculate image dimensions
//   const pdf = new jsPDF("p", "mm", "a4");
//   const imgProps = pdf.getImageProperties(screenshotDataURL);
//   const pdfWidth = pdf.internal.pageSize.getWidth();
//   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

//   // Add the image to the PDF and adjust dimensions to maintain aspect ratio
//   pdf.addImage(screenshotDataURL, "PNG", 0, 0, pdfWidth, pdfHeight);

//   // Trigger download
//   pdf.save("component-screenshot.pdf");
// };

export const getPixels = (number) => {
  if (number === 0 || number === "0") {
    return "0px";
  }
  // VIEWHEIGHT STRING
  if (isString(number) && number.endsWith("vh")) {
    return number;
  }
  // PERCENT STRING
  if (isString(number) && number.endsWith("%")) {
    return number;
  }
  // PIXEL STRING
  if (isString(number) && number.includes("px")) {
    return number;
  }
  // INTEGER
  if (parseInt(number)) {
    return `${parseInt(number)}px`;
  }
  return number;
};

export const truncateText = (text, length) => {
  if (typeof text === "object") {
    return "Object";
  }

  if (!isNaN(text)) {
    text = text && text.toString();
  }
  if (!text) {
    return "";
  }
  if (text.length > length) {
    return text.substring(0, length) + "...";
  } else {
    return text;
  }
};

export function validURL(str) {
  return str.includes("http");
}

export const getValueType = (value) => {
  let valueType = typeof value;

  if (Array.isArray(value)) {
    valueType = "array";
  } else if (value === "") {
    valueType = "empty string";
  } else if (value === null) {
    valueType = "null";
  }
  return valueType;
};

export const resizeImage = ({
  url,
  height,
  quality = null,
  forceJpg = false,
  extraMods = null,
}) => {
  if (!url) {
    return null;
  }

  url = url.toString();

  if (!url.includes("cloudinary")) {
    return url;
  }

  // Don't resize PDF
  if (url.includes(".pdf")) {
    return url;
  }

  const isResized = url.includes(`/h_`);

  let finalUrl = url;

  let mods = `h_${height}/`;

  if (extraMods) {
    mods = `${extraMods},${mods}/`;
  }

  if (quality) {
    mods = `${mods}q_${quality}/`;
  }

  if (forceJpg) {
    mods = `${mods}f_jpg/`;
  }

  if (isResized) {
    // ALREADY RESIZED
    const splitUrl = url.split("upload/");
    const existingMod = splitUrl[1].split("/")[0];
    finalUrl = url.replace(existingMod, mods);
  } else {
    // NOT RESIZED
    const splitUrl = url.split("upload/");
    const newUrl = `${splitUrl[0]}upload/${mods}${splitUrl[1]}`;
    finalUrl = newUrl;
  }

  return finalUrl;
};

export const parseBoolean = (value) => {
  if (typeof value === "boolean") return value;

  if (typeof value === "string") {
    const trimmedValue = value.trim().toLowerCase();

    switch (trimmedValue) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return false;
    }
  }

  return false;
};

export const getUrlParameter = (name, location) => {
  if (!name) {
    return null;
  }

  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(location.search);
  let value =
    results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));

  if (value === "true") {
    value = true;
  } else if (value === "false") {
    value = false;
  } else if (isInteger(value)) {
    return parseInt(value);
  }

  return value;
};

export const handleClientSidePagination = ({
  items,
  currentPage,
  itemsPerPage,
}) => {
  let firstItem = itemsPerPage * currentPage - itemsPerPage;
  let lastItem = itemsPerPage * currentPage;

  return items.filter((i, index) => index >= firstItem && index < lastItem);
};

export const goToRoute = (route, externalLink, navigate) => {
  if (route.includes("EXTERNAL_LINK") && validURL(externalLink)) {
    window.open(externalLink);
  } else if (validURL(route)) {
    window.open(route);
  } else {
    navigate(addSlash(route));
  }
};

export const addTransparency = (hex = "") => {
  if (!hex) {
    return hex;
  }

  const { length } = hex;
  const hasTransparency = length === 9;
  if (hasTransparency) {
    return hex;
  } else {
    return hex + "ff";
  }
};

export const upToFiveItems = (items = []) => {
  if (items.length > 5) {
    return items.slice(1);
  }
  return items;
};

// This function applies consistent styles to inputs across form fields
export const getFieldStyles = (p) => {
  return `
    padding: ${p.padding || "10px"};
    border: ${p.border || `1px solid var(--divider)`};
    border-width: ${p.borderWidth || "1px"};
    border-radius: ${getPixels(p.borderRadius || 10)};
    height: ${p.height || "40px"};
    font-weight: ${p.fontWeight || 400};
    background: ${p.background || "var(--input-background)"};
    color: ${p.color || "var(--text-color)"};
    font-size: ${p.fontSize ? getPixels(p.fontSize) : "14px"};
    width: ${p.width || "100%"};
  `;
};
