import { get, lowerCase } from "lodash";
import { getPixels, safeLower } from "app/utils/utils";
import { rApp, rTranslations } from "app/utils/recoil";

import { Icon } from ".";
import { colors } from "app/utils/theme";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

const CustomFilterButton = ({
  icon,
  background,
  border,
  displayValue,
  iconColor,
  textColor,
  onClick,
}) => {
  const activeApp = useRecoilValue(rApp);

  const inputRadius = get(activeApp, ["styling", "formInputRadius"], 8);

  return (
    <Container
      onClick={onClick}
      background={background}
      border={border}
      borderRadius={inputRadius}
    >
      {icon && (
        <Icon
          data={{
            icon,
            size: 17,
            margin: "0 2px 0 0",
            color: iconColor,
          }}
        />
      )}
      <Text color={textColor}>{displayValue}</Text>
    </Container>
  );
};

export default CustomFilterButton;

export const CustomFilterInput = ({
  icon,
  value,
  displayValue,
  showLabel = true,
  onChange,
  background,
  border,
  iconColor,
  textColor,
  placeholder,
  width,
}) => {
  const activeApp = useRecoilValue(rApp);
  const translations = useRecoilValue(rTranslations);

  const inputRadius = get(activeApp, ["styling", "formInputRadius"], 8);
  const anyText = get(translations, "filterAny", "any");

  return (
    <Container
      style={{ padding: icon && showLabel ? "0px" : "0px 0px 0px 10px" }}
      noHover
      background={background}
      border={border}
      borderRadius={inputRadius}
    >
      {icon && (
        <Icon
          data={{
            icon,
            size: 17,
            margin: "8px 2px 8px 8px",
            color: iconColor,
          }}
        />
      )}
      {showLabel !== false && (
        <Text color={textColor} style={{ cursor: "default" }}>
          {displayValue}
        </Text>
      )}
      <Input
        width={width}
        color={textColor}
        value={value}
        placeholder={placeholder || anyText}
        onChange={(e) => onChange(e.target.value)}
      />
    </Container>
  );
};

const Input = styled.input`
  border: 0px;
  background: transparent;
  font-size: 15px;
  padding: 5px 5px 5px 2px;
  font-weight: 500;
  font-size: 14px;
  width: ${(p) => p.width || "100px"};
  color: ${(p) => p.color};
  outline: 0px;
  &::placeholder {
    color: ${colors.grey3};
  }
`;

const Container = styled.div`
  padding: 10px;
  background: ${(p) => p.background};
  border: ${(p) => p.border};
  border-radius: ${(p) => getPixels(p.borderRadius || 10)};
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  height: 38px;
  ${(p) =>
    !p.noHover &&
    `
    &:hover {
      filter: brightness(95%);
    }
  `}
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  color: ${(p) => p.color};
`;
