import { Icon, Text } from ".";
import { useRef, useState } from "react";

import { colors } from "app/utils/theme";
import styled from "styled-components";

const LargeDocumentUpload = ({ data }) => {
  const [state, stateSet] = useState({
    isFetching: false,
    fileName: null,
  });

  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    data.onChange(file);
  };

  const handleRemoveFile = () => {
    stateSet({ ...state, fileName: null });
    if (data.onChange) {
      data.onChange("");
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {state.fileName && (
        <File>
          <Icon
            data={{ icon: "FiPaperclip", color: colors.primary, size: 18 }}
          />
          <Text
            data={{
              text: state.fileName,
              color: "var(--text-color)",
              fontSize: 15,
              fontWeight: 500,
            }}
          />
          <Icon
            data={{ icon: "FiX", color: colors.grey3, size: 18 }}
            onClick={handleRemoveFile}
          />
        </File>
      )}
      <UploadContainer
        onClick={() => document.getElementById("file-input").click()}
      >
        <Icon data={{ icon: "FiUploadCloud", color: colors.grey3, size: 23 }} />
        <Text
          data={{
            text: "Select Files",
            color: "var(--light-grey)",
            fontSize: 20,
            fontWeight: 400,
          }}
        />
      </UploadContainer>
      <input
        id="file-input"
        type="file"
        accept=".pdf, .docx, .txt"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default LargeDocumentUpload;

export const File = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--divider);
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  width: fit-content;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  background: var(--card-background);
  border: 1px solid var(--divider);
  cursor: pointer;
  padding: 20px;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  &:hover {
    filter: brightness(0.98);
  }
`;
