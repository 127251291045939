import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isEmpty } from "lodash";

import AdminForm from "../components/AdminForm";
import { Button } from "app/components";
import SettingsSection from "./SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { isAdmin } from "app/utils/utils";
import { useState } from "react";

const Password = ({ width }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [state, setState] = useState({ password: "", confirmPassword: "" });
  const [errors, setErrors] = useState({});

  const [isResetting, setIsResetting] = useState(false);

  const changes = state.password.length > 0 || state.confirmPassword.length > 0;

  const isValid = () => {
    const errors = {};

    if (!state.password.length)
      errors["password"] = "Please enter a valid password.";
    else if (state.password.length < 6)
      errors["password"] = "Please enter a more secure password.";
    else if (state.password !== state.confirmPassword)
      errors["confirmPassword"] = "Passwords do not match.";

    setErrors(errors);

    return isEmpty(errors);
  };

  const save = () => {
    if (!isValid()) return;
    setIsSaving(true);

    apiRequest
      .post("/reset_password/", { password: state.password })
      .then(() => {
        successNotification("Saved successfully");
      })
      .catch(() => {
        errorNotification("Something went wrong.");
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const fields = [
    {
      id: "password",
      label: "Password",
      type: "password",
      componentId: "Input",
      value: get(state, "password"),
    },
    {
      id: "confirmPassword",
      label: "Confirm Password",
      type: "password",
      componentId: "Input",
      value: get(state, "confirmPassword"),
    },
  ];

  return (
    <div>
      <SettingsSection
        title="Password"
        description={`Reset your password for logging into ${
          isAdmin ? "Scaale admin" : "the app"
        }`}
        width={width}
      >
        {isResetting ? (
          <AdminForm
            labelStyle="headingSm"
            sectionPadding="5px"
            fields={fields}
            orientation={"horizontal"}
            width="200px"
            submitText={"Update Password"}
            submit={changes && save}
            isFetching={isSaving}
            onChange={(k, v) => setState({ ...state, [k]: v })}
          />
        ) : (
          <Button
            data={{
              text: "Reset Password",
              onClick: () => setIsResetting(true),
              type: "basic",
            }}
          />
        )}
      </SettingsSection>
    </div>
  );
};

export default Password;
