import { Button, Column, FullScreenLoader, Row } from "app/components";
import { rIsFetchingTool, rUser } from "app/utils/recoil";
import useTool, { useFetchTool } from "app/utils/useTool";

import AuthSidebar from "app/adminApp/home/AuthSidebar";
import BackNav from "app/components/BackNav";
import CourseOutline from "./CourseOutline";
import PublicWrapper from "app/public/PublicWrapper";
import { get } from "lodash";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const ToolDetails = () => {
  const { tool } = useTool();

  const isFetchingTool = useRecoilValue(rIsFetchingTool);

  useFetchTool();

  const navigate = useNavigate();

  const user = useRecoilValue(rUser);

  if (isFetchingTool || !tool) {
    return <FullScreenLoader />;
  }

  const steps = get(tool, "steps", []);

  return (
    <PublicWrapper>
      <ContentRow>
        {user && <AuthSidebar />}
        <MainContent>
          <BackNav onClick={() => navigate("/tools")} text={"All Tools"} />
          <Content>
            <HeaderRow>
              <Column>
                <ToolName>{tool.name}</ToolName>
                <ToolDescription>
                  {tool.description ||
                    "This tool is missing a detailed description, so you'll just have to try it out to see what it does."}
                </ToolDescription>

                <Row
                  margin="25px 0 0 0"
                  width="100%"
                  justifyContent="space-between"
                  style={{ flex: 1 }}
                >
                  <CreatedBy>
                    Created by{" "}
                    <Link
                    // onClick={() => navigate(`/app/${tool.app_name}`)}
                    >
                      {tool.app_name}
                    </Link>
                  </CreatedBy>
                  <ToolUses>Used {tool.session_count} times</ToolUses>
                </Row>
                <Button
                  data={{
                    size: "extraLarge",
                    text: "Use Tool",
                    icon: "FiArrowRight",
                    flippedIcon: true,
                    onClick: () => navigate(`/tool/${tool.id}`),
                    padding: "14px 32px 14px 32px",
                    margin: "30px 0 0 0",
                  }}
                />
              </Column>
              <ToolImage src={tool.image} />
            </HeaderRow>
            <CourseOutlineHeader>Tool Details</CourseOutlineHeader>
            <ToolUses style={{ marginBottom: "15px" }}>
              {steps.filter((s) => s.groupLabel).length} sections,{" "}
              {get(steps, "length", 0)} steps
            </ToolUses>
            <CourseOutline />
          </Content>
        </MainContent>
      </ContentRow>
    </PublicWrapper>
  );
};

const CourseOutlineHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-grey);
  margin-top: 70px;
  margin-bottom: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--card-background);
  padding: 40px;
  border-radius: 10px;
  margin-top: 30px;
  max-width: 1000px;
  @media (max-width: 1000px) {
    max-width: 100%;
    padding: 0px;
    background-color: transparent;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  gap: 80px;
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    gap: 30px;
  }
`;

const ToolImage = styled.img`
  width: 100%;
  max-height: 230px;
  max-width: 400px;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border: 1px solid var(--divider);
  border-radius: 10px;
`;

const ToolName = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: var(--text-color);
`;

const ToolDescription = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: var(--dark-grey);
  margin-top: 15px;
`;

const ToolUses = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: var(--light-grey);
`;

const CreatedBy = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: var(--light-grey);
`;

const Link = styled.a`
  color: var(--light-grey);
  text-decoration: underline;
`;

const MainContent = styled.div`
  flex: 1;
  overflow-x: hidden;
`;

const ContentRow = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
`;

export default ToolDetails;
