import { get, startCase, truncate } from "lodash";
import { getUniqueRoute, safeArray } from "app/utils/utils";
import useTool, { useFetchTool } from "app/utils/useTool";

import { AdminForm } from "app/adminApp/components";
import Crumbs from "app/components/Crumbs";
import { SelectToggle } from "app/components";
import { rActiveStepIndex } from "app/utils/recoil";
import { useRecoilState } from "recoil";
import { useState } from "react";

const ToolConfig = () => {
  const [activeGroupIndex, setActiveGroupIndex] = useState(null);

  const [activeStepIndex, setActiveStepIndex] =
    useRecoilState(rActiveStepIndex);

  const [tab, setTab] = useState("basics");

  const { activeStepConfig } = useTool();

  const { tool, setTool } = useFetchTool();

  const steps = safeArray(tool, "steps");

  const stepType = get(activeStepConfig, "type", "");

  const setActiveStep = (newData) => {
    const newSteps = steps.map((step, index) =>
      index === activeStepIndex ? { ...step, ...newData } : step
    );
    setTool({
      steps: newSteps,
    });
  };

  const outputComponent = {
    id: "output",
    componentId: "MultiForm",
    section: "Output",
    width: "400px",
    noLabel: true,
    newObject: {
      label: "New Section",
      type: "text",
    },
    labelSingular: "Section",
    tab: "output",
    fields: [
      {
        id: "label",
        label: "Label",
        componentId: "Input",
      },
      {
        id: "columnSpan",
        label: "Column Span",
        componentId: "SelectToggle",
        tabs: [
          { label: "1", value: 1 },
          { label: "2", value: 2 },
          { label: "3", value: 3 },
        ],
      },
      {
        id: "rowSpan",
        label: "Row Span",
        componentId: "SelectToggle",
        tabs: [
          { label: "1", value: 1 },
          { label: "2", value: 2 },
        ],
      },
      {
        id: "value",
        label: "Markdown",
        componentId: "MarkdownPopupEditor",
      },
    ],
  };

  const contextComponent = {
    id: "context",
    componentId: "MultiForm",
    section: "Context",
    sectionHint:
      "Provide more context for the AI, like industry-specific knowledge or tactics that will help the AI produce better results",
    width: "400px",
    noLabel: true,
    newObject: {
      label: "New Context",
      type: "text",
    },
    labelSingular: "Context",
    tab: "context",
    fields: [
      {
        id: "type",
        label: "Type",
        componentId: "Select",
        options: [
          { label: "Text", value: "text" },
          { label: "Document", value: "document" },
        ],
      },
      {
        id: "label",
        label: "Label",
        componentId: "Input",
      },
      {
        id: "description",
        label: "Description",
        componentId: "TextArea",
      },
      {
        id: "content",
        label: "Content",
        componentId: "DocumentUpload",
        displayCondition: (f) => get(f, "type", "") === "document",
      },
      {
        id: "content",
        label: "Content",
        componentId: "TextArea",
        displayCondition: (f) => get(f, "type", "") === "text",
      },
    ],
  };

  const activeStepInputKeys = get(activeStepConfig, "inputs", []).map((i) =>
    get(i, "key", "")
  );

  const inputsComponent = {
    id: "inputs",
    componentId: "MultiForm",
    section: "Form Inputs",
    sectionHint: "Define inputs for the user to fill out at this step.",
    tab: "input",
    width: "400px",
    noLabel: true,
    newObject: {
      label: "New Input",
      componentId: "Input",
      key: getUniqueRoute(activeStepInputKeys, "new_input"),
    },
    labelSingular: "Input",
    fields: [
      {
        id: "key",
        label: "Key",
        componentId: "Input",
      },
      {
        id: "label",
        label: "Label",
        componentId: "Input",
      },
      {
        id: "description",
        label: "Description",
        componentId: "TextArea",
      },
      {
        id: "hint",
        label: "Hint",
        componentId: "TextArea",
      },
      {
        id: "placeholder",
        label: "Placeholder",
        componentId: "Input",
      },
      {
        id: "componentId",
        label: "Type",
        componentId: "Select",
        options: [
          { label: "Input", value: "Input" },
          { label: "Select", value: "Select" },
          { label: "Text Area", value: "TextArea" },
          { label: "Document Upload", value: "DocumentUpload" },
        ],
      },
      {
        id: "options",
        label: "Options",
        componentId: "DataGrid",
        orientation: "vertical",
        hint: "Define the options to appear in your select dropdown. Auto detected any unique values in the sheet. Adjust them as you see fit.",
        newObject: { label: "New Item", value: "New Value" },
        columns: [
          {
            key: "label",
            componentId: "Input",
          },
          {
            key: "value",
            componentId: "Input",
          },
        ],
        displayCondition: (f) =>
          ["Select", "MultiSelect"].includes(get(f, "componentId")),
      },
      {
        id: "columnSpan",
        label: "Column Span",
        hint: "How many 'columns' of width this field takes up in the form.",
        componentId: "SelectToggle",
        tabs: [
          {
            label: "1",
            value: 1,
          },
          {
            label: "2",
            value: 2,
          },
        ],
        required: false,
      },
    ],
  };

  let otherGroupFields = [];
  get(activeStepConfig, "groups", [])
    .filter((g, i) => i !== activeGroupIndex)
    .forEach((g) => {
      get(g, "fields", "")
        .split(",")
        .map((t) => t.trim())
        .filter((x) => Boolean(x))
        .forEach((f) => {
          otherGroupFields.push(f);
        });
    });

  const stepTypeField = {
    id: "type",
    section: "Step Type",
    sectionHint: "Determine the type of the step",
    componentId: "Select",
    tab: "basics",
    options: [
      { label: "Input", value: "input" },
      { label: "Output", value: "output" },
      { label: "Education", value: "education" },
    ],
  };

  const universalFields = [
    {
      id: "groupLabel",
      section: "Group Label",
      sectionHint: "Turn this input into a group.",
      componentId: "Input",
      tab: "basics",
    },
    stepTypeField,
  ];

  const relevantSteps = steps.filter(
    (s, stepIndex) => stepIndex < activeStepIndex
  );

  const inputFields = [
    inputsComponent,
    {
      id: "groups",
      componentId: "MultiForm",
      labelSingular: "Group",
      width: "400px",
      section: "Input Groups",
      itemClick: (gIndex) => setActiveGroupIndex(gIndex),
      tab: "input",
      fields: [
        {
          id: "label",
          label: "Label",
          componentId: "Input",
        },
        {
          id: "description",
          label: "Description",
          componentId: "TextArea",
        },
        {
          id: "fields",
          label: "Fields",
          componentId: "MultiSelect",
          options: activeStepInputKeys
            .filter((h) => !otherGroupFields.includes(h))
            .map((h) => ({
              label: startCase(h),
              value: h,
            })),
        },
      ],
    },
    {
      id: "buttonText",
      componentId: "Input",
      section: "Button Text",
      sectionHint: "The text to display on the button.",
      placeholder: "Button Text",
      hint: "The text to display on the button.",
      tab: "input",
    },
  ];

  const outputFormat = {
    id: "outputFormat",
    section: "Output Format",
    sectionHint: "Determine the format of the output",
    componentId: "Select",
    defaultValue: "text",
    tab: "basics",
    options: [
      { label: "Text", value: "text" },
      { label: "Text Array", value: "text_array" },
      { label: "Object", value: "object" },
      { label: "Object Array", value: "object_array" },
    ],
    displayCondition: (f) => get(f, "type", "") === "output",
  };

  const outputFields = [
    outputFormat,
    {
      displayCondition: (field) =>
        ["object", "object_array"].includes(get(field, "outputFormat", "text")),
      id: "objectFields",
      section: "Object Fields",
      sectionHint: "Define the fields within your generated objects.",
      componentId: "MultiForm",
      width: "400px",
      noLabel: true,
      newObject: {
        label: "New Field",
        key: "new_field",
      },
      labelSingular: "Field",
      getItemLabel: (item) => get(item, "key", ""),
      tab: "basics",
      fields: [
        {
          id: "key",
          label: "Key",
          componentId: "Input",
        },
        {
          id: "description",
          label: "Description",
          componentId: "TextArea",
        },
        {
          id: "type",
          label: "Type",
          componentId: "Select",
          options: [
            { label: "Text", value: "text" },
            { label: "Text Array", value: "text_array" },
          ],
        },
        {
          id: "displayType",
          label: "Display Type",
          componentId: "Select",
          defaultValue: "text",
          options: [
            { label: "Header", value: "header" },
            { label: "Text", value: "text" },
          ],
        },
      ],
    },
    {
      id: "displayType",
      section: "Display Type",
      componentId: "Select",
      tab: "basics",
      defaultValue: null,
      options: [
        { label: "Default", value: null },
        { label: "Options Single", value: "options_single" },
        { label: "Options Multiple", value: "options_multiple" },
      ],
    },
    {
      id: "prompt",
      sectionHint: "The prompt passed to the AI in addition to other context.",
      componentId: "TextArea",
      minHeight: "150px",
      tab: "basics",
      section: "AI Prompt",
    },

    {
      id: "contextMap",
      label: "Context Map",
      componentId: "MultiSelect",
      tab: "context",
      options: safeArray(tool, "context").map((c) => ({
        label: get(c, "label", ""),
        value: get(c, "id", ""),
      })),
    },
    {
      id: "inputMap",
      label: "Input Map",
      componentId: "MultiSelect",
      tab: "context",
      options: relevantSteps
        .filter((s) => get(s, "type", "") === "input")
        .map((s, si) => ({
          label: get(s, "label", `Step ${si + 1}`),
          value: s.id,
        })),
    },
    {
      id: "outputMap",
      label: "Output Map",
      componentId: "MultiSelect",
      tab: "context",
      options: relevantSteps
        .filter((s) => get(s, "type", "") === "output")
        .map((s, si) => ({
          label: get(s, "label", `Step ${si + 1}`),
          value: s.id,
        })),
    },
  ];

  const educationComponent = {
    id: "education",
    componentId: "MultiForm",
    section: "Education",
    width: "400px",
    noLabel: true,
    newObject: {
      label: "New Education",
      type: "text",
    },
    labelSingular: "Education",
    tab: "education",
    fields: [
      {
        id: "height",
        label: "Height",
        componentId: "Input",
        type: "number",
        displayCondition: (f) =>
          ["image", "carousel", "video"].includes(get(f, "type", "text")),
      },
      {
        id: "width",
        label: "Width",
        componentId: "Input",
        type: "number",
        displayCondition: (f) =>
          ["image", "carousel", "video"].includes(get(f, "type", "text")),
      },
      {
        id: "objectFit",
        label: "Object Fit",
        componentId: "Select",
        displayCondition: (f) =>
          ["image", "carousel"].includes(get(f, "type", "text")),
        defaultValue: "contain",
        options: [
          { label: "Contain", value: "contain" },
          { label: "Cover", value: "cover" },
        ],
      },
      {
        id: "type",
        label: "Type",
        componentId: "Select",
        options: [
          { label: "Text", value: "text" },
          { label: "Image", value: "image" },
          { label: "Image Carousel", value: "carousel" },
          { label: "Video", value: "video" },
        ],
      },
      {
        id: "label",
        label: "Label",
        componentId: "Input",
      },
      {
        id: "content_url",
        label: "Image",
        componentId: "ImageUpload",
        displayCondition: (f) => get(f, "type", "text") === "image",
      },
      {
        id: "content_url",
        label: "Video URL",
        componentId: "Input",
        displayCondition: (f) => get(f, "type", "text") === "video",
      },
      {
        id: "markdown",
        label: "Markdown",
        componentId: "MarkdownPopupEditor",
        displayCondition: (f) => get(f, "type", "text") === "text",
      },
      {
        id: "carousel_images",
        componentId: "MultiForm",
        newObject: {
          label: "New Image",
        },
        labelSingular: "Image",
        fields: [
          {
            id: "label",
            label: "Label",
            componentId: "Input",
          },
          {
            id: "image",
            label: "Image",
            componentId: "ImageUpload",
          },
        ],
      },
    ],
  };

  const educationFields = [educationComponent];

  let displayFields = [...universalFields];
  if (stepType === "education") {
    displayFields = [...universalFields, ...educationFields];
  } else if (stepType === "input") {
    displayFields = [...universalFields, ...inputFields];
  } else if (stepType === "output") {
    displayFields = [...universalFields, ...outputFields];
  }

  const stepFields = displayFields
    .filter(
      (f) => !get(f, "displayCondition") || f.displayCondition(activeStepConfig)
    )
    .map((f) => ({
      ...f,
      value: get(activeStepConfig, f.id, null),
    }));

  const fields = [
    {
      id: "steps",
      section: "Steps",
      componentId: "MultiTile",
      orientation: "vertical",
      labelSingular: "Step",
      getItemLabel: (item) => `${truncate(get(item, "label", ""), 40)}`,
      itemClick: (index) => setActiveStepIndex(index),
      tab: "basics",
      newObject: {
        label: "New Step",
        type: "input",
      },
      labelSingular: "Step",
      value: steps.map((s) => ({
        ...s,
        icon: get(
          {
            input: "BsPencilFill",
            output: "BsFillSendFill",
            education: "FaBookOpen",
          },
          get(s, "type", "input")
        ),
        accent: get(s, "groupLabel"),
        iconColor: get(
          {
            input: "#ca7580c0",
            output: "#669ac5c0",
            education: "#ebc17dc0",
          },
          get(s, "type", "input")
        ),
      })),
      section: "steps",
      sectionHint:
        "Break down your content generation into steps. This will help the AI understand the structure of your content.",
    },
    outputComponent,
    { ...contextComponent, value: get(tool, "context", []), tab: "basics" },
    {
      id: "public",
      componentId: "Switch",
      orientation: "horizontal",
      section: "allowPublicAccess",
      tab: "other",
      sectionHint:
        "Public pages are accessible to everyone, even if they are not logged in.",
    },
    {
      id: "published",
      componentId: "Switch",
      orientation: "horizontal",
      section: "published",
      sectionHint: "If unpublished, it will not be visible to users.",
      tab: "other",
    },
    {
      id: "description",
      componentId: "TextArea",
      section: "Tool Description",
      sectionHint: "A description of the tool to show on the details page.",
      tab: "other",
    },
    {
      id: "image",
      label: "Image",
      componentId: "ImageUpload",
      tab: "other",
    },
  ]
    .filter((f) => !get(f, "displayCondition") || f.displayCondition(tool))
    .map((f) => ({
      ...f,
      value: f.value || get(tool, f.id, null),
    }));

  const allFields = [...fields];

  return (
    <>
      {/* {showSetup && (
        <Modal
          hide={() => setShowSetup(false)}
          header={{
            title: "Describe the tool you want to create",
          }}
        >
          <AdminForm
            sectionPadding={"0px"}
            labelStyle="bodyLg"
            onChange={(k, v) => setAIPrompt(v)}
            isFetching={isGenerating}
            submit={() => {
              setIsGenerating(true);
              apiRequest
                .post("/generate_flow/", { prompt: aiPrompt })
                .then((r) => {
                  const resData = get(r, "data", {});
                  setTool({ ...resData });
                  setShowSetup(false);
                  setIsGenerating(false);
                });
            }}
            fields={[
              {
                id: "ai_prompt",
                label: "We'll use AI to generate an initial flow for you.",
                componentId: "TextArea",
              },
            ]}
          />
        </Modal>
      )} */}

      {/* SHOW ROOT */}
      {activeStepIndex === null && (
        <>
          <SelectToggle
            data={{
              margin: "15px 15px 0 15px",
              onChange: (v) => setTab(v),
              tabs: [
                {
                  label: "Basics",
                  value: "basics",
                  active: !tab || tab === "basics",
                },
                {
                  label: "Output",
                  value: "output",
                  active: tab === "output",
                },
                {
                  label: "Other",
                  value: "other",
                  active: tab === "other",
                },
              ],
            }}
          />

          <AdminForm
            sectionPadding={"15px"}
            borderBottom={true}
            fields={filterByTab(allFields, tab)}
            onChange={(k, v) => setTool({ [k]: v })}
          />
        </>
      )}

      {activeStepIndex !== null && (
        <Crumbs
          items={[
            {
              text: "Steps",
              onClick: () => setActiveStepIndex(null),
            },
            {
              text: get(
                activeStepConfig,
                "label",
                `Step ${activeStepIndex + 1}`
              ),
            },
          ]}
        />
      )}

      {/* SHOW ACTIVE STEP */}
      {activeStepIndex !== null && (
        <AdminForm
          sectionPadding={"15px"}
          borderBottom={true}
          fields={stepFields}
          onChange={(k, v) => setActiveStep({ [k]: v })}
        />
      )}
    </>
  );
};

export default ToolConfig;

const filterByTab = (fields, tab) => {
  return fields.filter((f) => {
    if (!f.tab) {
      // This is to make sure we don't accidentally 'lose' any fields
      return true;
    }

    return f.tab === tab;
  });
};
