import PublicHeader from "./PublicHeader";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { rApp } from "app/utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

const PublicWrapper = ({ children }) => {
  const activeApp = useRecoilValue(rApp);
  const navigation = get(activeApp, "navigation", {});
  const navigationType = get(navigation, "type", "sidebar");
  const backgroundColor = colors.pitchBackground;

  return (
    <MainContainer>
      <Body navigationType={navigationType} backgroundColor={backgroundColor}>
        <PublicHeader />
        <LiveContainer>{children}</LiveContainer>
      </Body>
    </MainContainer>
  );
};

export default PublicWrapper;

const MainContainer = styled.div`
  padding: 0px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const Body = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  background: var(--background);
`;

const LiveContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  padding: 10px 30px 30px 30px;
`;
