import { colors, spacing } from "app/utils/theme";

import { Checkbox as MUICheckbox } from "@mui/material";
import { Text } from ".";
import { get } from "lodash";
import { parseBoolean } from "app/utils/utils";
import { rApp } from "app/utils/recoil";
import { useRecoilValue } from "recoil";

const Checkbox = ({ data }) => {
  const value = get(data, "value", false);

  const activeApp = useRecoilValue(rApp);

  const isActive = parseBoolean(value);

  const primaryColor = get(activeApp, "primary_color", colors.primary);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: spacing.s1,
        marginTop: spacing.s1,
      }}
    >
      <MUICheckbox
        color="primary"
        checked={isActive}
        disabled={data.disabled}
        onChange={() => data?.onChange(!isActive)}
        value={isActive}
        style={{ padding: "0px" }}
        sx={{
          color: "var(--dark-grey)",
          "&.Mui-checked": {
            color: primaryColor,
          },
        }}
      />
      <Text
        data={{ text: data.label, margin: "0 0 4px 0", fontStyle: "bodySm" }}
      />
    </div>
  );
};

export default Checkbox;
