import { fontObject } from "app/utils/theme";
import { get } from "lodash";
import { getFieldStyles } from "app/utils/utils";
import styled from "styled-components";

const TextArea = ({ data }) => {
  const { value, onChange, placeholder, border } = data;

  const fontStyle = get(data, "fontStyle", "bodyMd");
  const styleObject = get(fontObject, fontStyle);

  let backgroundColor = data.backgroundColor || data.background;
  return (
    <StyledTextArea
      onKeyDown={(e) => e.stopPropagation()}
      {...styleObject}
      {...data}
      defaultValue=""
      onChange={(e) => onChange(e.target.value)}
      value={value}
      placeholder={placeholder}
      disabled={data.disabled}
      border={border}
      minHeight={data.minHeight}
      maxLength={data.maxLength}
      background={backgroundColor}
    />
  );
};

export default TextArea;

const StyledTextArea = styled.textarea`
  ${(p) => getFieldStyles(p)};
  min-height: ${(p) => p.minHeight || "70px"};
  font-family: ${(p) => (p.monospace ? "monospace" : "inherit")};
  resize: ${(p) => p.resize || "vertical"};
  &:focus {
    outline: none;
  }
  ${(p) =>
    p.placeholderColor &&
    `
    ::placeholder {
      color: ${p.placeholderColor};
    }
    
    ::-ms-input-placeholder { /* Edge 12 -18 */
    color: ${p.placeholderColor};
    }
  `}
`;
