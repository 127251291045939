import {
  rTool,
  rWebsocket,
  rWebsocketRequests,
  rWebsocketSessionId,
} from "./utils/recoil";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { get } from "lodash";
import { getUrlParameter } from "./utils/utils";
import { useLocation } from "react-router-dom";
import useTool from "./utils/useTool";

const useWebsocket = () => {
  const { tool } = useTool();

  const toolId = get(tool, "id");

  const [websocket, setWebsocket] = useRecoilState(rWebsocket);
  const [history, setHistory] = useState([]);
  const [responses, setResponses] = useState([]);

  const websocketRequests = useRecoilValue(rWebsocketRequests);
  const [websocketSessionId, setWebsocketSessionId] =
    useRecoilState(rWebsocketSessionId);

  useEffect(() => {
    if (!websocketSessionId) {
      const sessionId = (
        Math.random().toString(36) +
        Math.random().toString(36) +
        Math.random().toString(36) +
        Math.random().toString(36)
      ).substr(2, 32);

      setWebsocketSessionId(sessionId);
    }
  }, [websocketSessionId, setWebsocketSessionId]);

  const location = useLocation();
  const sessionId = getUrlParameter("sessionId", location);

  const shouldConnect = !!sessionId;

  useEffect(() => {
    const toProcess = responses.filter((r) => !history.includes(r.instance_id));
    toProcess.forEach((r) => processResponse(r));
  }, [responses]);

  const processResponse = (response) => {
    const instanceId = get(response, "instance_id");

    const matchingInstance = websocketRequests.find(
      (r) => r.instanceId === instanceId
    );

    if (matchingInstance && !history.includes(instanceId)) {
      const callback = get(matchingInstance, "callback");
      callback(response);

      // Add instanceId to history to prevent duplicate runs
      setHistory((prevHistory) => [...prevHistory, instanceId]);
    }
  };

  // Function to initialize WebSocket connection
  const connect = useCallback(() => {
    if (
      tool &&
      websocketSessionId &&
      shouldConnect &&
      (!websocket || websocket.readyState !== 1)
    ) {
      const wsURL = `wss://f8lzd569pg.execute-api.ca-central-1.amazonaws.com/production?function=scaale_connect&tool_id=${tool.id}&session_id=${websocketSessionId}`;

      const ws = new WebSocket(wsURL);
      ws.onopen = () => {
        console.log("WebSocket Connected");
        setWebsocket(ws);
      };

      ws.onmessage = (event) => {
        const wsResponse = JSON.parse(event.data);
        setResponses((r) => [...r, wsResponse]);
      };

      ws.onclose = () => {
        console.log("WebSocket Disconnected");
        setWebsocket(null);
      };

      return () => ws.close();
    }
  }, [toolId, websocketSessionId, shouldConnect, websocket]);
  // Function to close WebSocket connection
  const disconnect = useCallback(() => {
    if (websocket) {
      websocket.close();
    }
  }, [websocket]);

  useEffect(() => {
    connect();
    return () => disconnect();
  }, [connect, disconnect]);

  return { connect, disconnect };
};

export default useWebsocket;
