import { Button, Icon, Modal, PopupMenu, Spinner } from "app/components";
import { HelpCard, HelpCardsContainer } from "./HelpCard";
import { rApp, rClearConfig, rTheme, rUser } from "app/utils/recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Cookies from "js-cookie";
import { addSlash } from "app/utils/utils";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import scaaleLogo from "assets/scaale-logo-blue-white.png";
import scaaleLogoWhite from "assets/scaale-logo-blue-black.png";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useState } from "react";

const AdminWrapper = ({
  children,
  contentWidth = null,
  padding = "40px",
  isFetching = false,
}) => {
  const location = useLocation();

  const path = get(location, "pathname");

  const user = useRecoilValue(rUser);

  const clearConfig = useSetRecoilState(rClearConfig);

  const app = useRecoilValue(rApp);

  const theme = useRecoilValue(rTheme);

  const helpCards = [
    {
      title: "YouTube",
      description: "Watch video tutorials and guides on our YouTube channel.",
      onClick: () => window.open("https://www.youtube.com/@frontlyapp"),
      linkText: "Go to YouTube",
      icon: "FiYoutube",
    },
    {
      title: "Help Articles",
      description: "Browse our extensive collection of help articles.",
      onClick: () => window.open("https://help.curatorapp.ai"),
      linkText: "Read Help Articles",
      icon: "FiBook",
    },
    {
      title: "Experts Marketplace",
      description: "Find and hire experts for your projects.",
      onClick: () => navigate("/experts-marketplace"),
      linkText: "Explore Experts Marketplace",
      icon: "FiUsers",
    },
    {
      title: "Discord Community",
      description: "Join 1,500+ users for discussion, support and inspiration.",
      onClick: () => window.open("https://discord.gg/8wnQndpBGU"),
      linkText: "Visit Community Forum",
      icon: "FiMessageCircle",
    },
  ];

  const currentApp = [
    {
      icon: "FiGrid",
      label: "Tools",
      route: "/admin/tools",
    },
    {
      icon: "FiUsers",
      label: "Users",
      route: "/admin/users",
    },
    {
      icon: "FiSettings",
      label: "Settings",
      route: "/admin/settings",
    },
  ];

  const navigate = useNavigate();

  let popupLinks = [
    {
      text: "Files",
      icon: "FiImage",
      onClick: () => navigate("/files"),
    },
    {
      text: "Billing",
      icon: "FiCreditCard",
      onClick: () => navigate("/billing"),
    },
    {
      text: "Logout",
      icon: "FiLogOut",
      onClick: () => {
        clearConfig();
        Cookies.remove("accessToken");
        localStorage.removeItem("appId");
        successNotification("Logged out.");
        navigate("/login");
      },
    },
  ];

  const [showHelp, setShowHelp] = useState(false);

  const getAppLink = (app) => {
    const customDomain = get(app, ["custom_domain", "custom_domain"]);

    const domain = customDomain || get(app, "subdomain");

    const appUrl = customDomain
      ? `https://${customDomain}`
      : `https://${domain}.curatorapp.ai`;

    return appUrl;
  };

  return (
    <Container>
      {showHelp && (
        <Modal
          minWidth="650px"
          hide={() => setShowHelp(false)}
          header={{
            title: "Need help? We've got you covered",
          }}
        >
          <HelpCardsContainer>
            {helpCards.map((card) => (
              <HelpCard data={card} />
            ))}
          </HelpCardsContainer>
        </Modal>
      )}
      <Body>
        <Sidebar>
          <LogoRow>
            <Logo src={theme === "dark" ? scaaleLogo : scaaleLogoWhite} />
            <Button
              data={{
                onClick: () => window.open(getAppLink(app)),
                type: "basic",
                icon: "FiExternalLink",
                backgroundColor: "var(--card-background)",
                border: "1px solid var(--button-border)",
              }}
            />
          </LogoRow>

          <SplitContent>
            <div>
              {/* <SectionLabel>Current App</SectionLabel> */}
              <ItemsList>
                {currentApp.map((item) => {
                  const isActive = addSlash(path).includes(
                    addSlash(item.route)
                  );
                  return <Item item={item} active={isActive} />;
                })}
              </ItemsList>
            </div>

            <BottomSectionContainer>
              <PopupMenuWrapper>
                <PopupMenu
                  data={{
                    backgroundColor: "#9a9db3",
                    textColor: "white",
                    width: "160px",
                    activeUser: user,
                    links: popupLinks,
                  }}
                />
              </PopupMenuWrapper>
            </BottomSectionContainer>
          </SplitContent>
        </Sidebar>
        <Content>
          <ContentWidth width={contentWidth} padding={padding}>
            {isFetching ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                }}
              >
                <Spinner size={40} />
              </div>
            ) : (
              children
            )}
          </ContentWidth>
        </Content>
      </Body>
    </Container>
  );
};

const BottomSectionContainer = styled.div``;

const PopupMenuWrapper = styled.div`
  margin: 0 0 5px 0;
  padding: 8px;
  border-radius: 10px;
  width: 100%;
  border: 1px solid var(--divider);
  &:hover {
    background: var(--divider);
  }
`;

export const Item = ({ item, active }) => {
  const navigate = useNavigate();

  // Get onClick
  let onClick = null;
  if (item.onClick) {
    onClick = item.onClick;
  } else if (item.link) {
    onClick = () => window.open(item.link);
  } else if (item.route) {
    onClick = () => navigate(item.route);
  }

  return (
    <ItemContainer active={active} onClick={onClick}>
      <Icon
        data={{
          icon: item.icon,
          size: 20,
          // color: active ? colors.primary : "var(--text-color)",
          color: "var(--text-color)",
        }}
      />
      <ItemLabel active={active}>{item.label}</ItemLabel>
      {item.badge && (
        <div
          style={{
            background: colors.primary,
            color: "white",
            borderRadius: "4px",
            padding: "2px 4px 2px 4px",
            fontSize: "12px",
            fontWeight: 500,
          }}
        >
          {item.badge}
        </div>
      )}
    </ItemContainer>
  );
};

export default AdminWrapper;

const LogoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 14px 12px 14px;
  border-bottom: 1px solid var(--divider);
`;

const SplitContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  padding: 12px;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Body = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const Sidebar = styled.div`
  align-items: flex-start;
  min-width: 240px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid var(--divider);
  z-index: 9999;
  background: var(--card-background);
`;

const Content = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: var(--background);
`;

const ContentWidth = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: ${(p) => p.padding};
  flex: 1;
`;

const Logo = styled.img`
  height: 26px;
`;

const ItemsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 11px 11px 11px 11px;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  ${(p) =>
    p.active
      ? `
        background: var(--divider);
      `
      : `
      opacity: 0.8;
    &:hover {
      background: var(--divider);
    }
  `};
`;

const ItemLabel = styled.div`
  font-size: 17px;
  font-weight: 500;
  // color: ${(p) => (p.active ? colors.primary : "var(--text-color)")};
  color: var(--text-color);
`;

// const SectionLabel = styled.div`
//   font-size: 12px;
//   font-weight: 500;
//   color: ${colors.grey3};
//   padding: 0 22px 5px 11px;
//   margin-top: 15px;
// `;
