import Carousel from "./Carousel";
import { MarkdownViewer } from "app/components";
import Vimeo from "app/components/Vimeo";
import { get } from "lodash";
import { getPixels } from "app/utils/utils";
import styled from "styled-components";

const Education = ({ stepConfig, previewWidth, isAdminEditor }) => {
  const education = get(stepConfig, "education", []);

  if (!education) {
    return null;
  }

  return (
    <EducationContainer>
      {education.map((e) => {
        const type = get(e, "type", "text");
        if (type === "carousel") {
          return <Carousel data={e} previewWidth={previewWidth} />;
        }

        // Video
        if (type === "video") {
          return (
            <Vimeo
              url={get(e, "content_url", "")}
              title={get(e, "label", "")}
              width={get(e, "width", 620)}
              height={get(e, "height", 400)}
              previewWidth={previewWidth}
            />
          );
        }

        // Markdown
        if (type === "text") {
          // TODO - Make this education step editable right here
          // if (isAdminEditor) {
          //   return (
          //     <MarkdownEditor
          //       markdown={get(e, "markdown", "")}
          //       onChange={(value) => setActiveStep({ [key]: value })}
          //     />
          //   );
          // }

          return <MarkdownViewer markdown={get(e, "markdown", "")} />;
        }

        // Image
        if (type === "image") {
          return (
            <Image
              src={get(e, "content_url", "")}
              width={get(e, "width")}
              height={get(e, "height")}
              objectFit={get(e, "objectFit")}
            />
          );
        }

        return null;
      })}
    </EducationContainer>
  );
};

export default Education;

const Image = styled.img`
  max-width: ${({ width }) => (width ? getPixels(width) : "auto")};
  max-height: ${({ height }) => (height ? getPixels(height) : "300px")};
  object-fit: ${({ objectFit }) => objectFit || "contain"};
  border-radius: 10px;
`;

const EducationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  width: 100%;
`;
