import { colors, fontObject } from "app/utils/theme";
import { get, toString } from "lodash";
import { getPixels, truncateText } from "app/utils/utils";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { rTranslations } from "app/utils/recoil";
import { useRecoilValue } from "recoil";

const MultiSelect = ({ data }) => {
  const translations = useRecoilValue(rTranslations);
  const options = get(data, "options", []).map((o) => ({
    ...o,
    value: toString(o.value),
  }));

  const fontStyle = get(data, "fontStyle", "bodyMd");
  const styleObject = get(fontObject, fontStyle);

  let value = get(data, "value") || get(data, "defaultValue") || "";

  // Split the comma separated string values
  const splitValues = value ? value.split(",").map((req) => req.trim()) : [];

  const selectText = get(translations, "selectText", "Select");

  let backgroundColor =
    data.backgroundColor || data.background || "var(--input-background)";

  // TODO - Consider using this here
  // ${getFieldStyles(data)};

  return (
    <div style={{ width: data.width || "auto" }}>
      <style>{`
          .MuiAutocomplete-inputRoot {
            border: ${data.border || `1px solid var(--divider)`} !important;
            background-color: ${backgroundColor} !important;
            border-radius: ${getPixels(data.borderRadius || 10)} !important;
            color: var(--text-color);
            height: ${data.height || "40px"};
            padding: 0 0 0 10px !important;
            margin: 0px !important;
            font-size: ${getPixels(get(styleObject, "fontSize"))} !important;
          }
        `}</style>

      <Autocomplete
        disabled={data.disabled}
        multiple
        value={splitValues}
        options={options.map((o) => o.value)}
        getOptionLabel={(option) => {
          return get(
            options.find((o) => o.value === option),
            "label",
            null
          );
        }}
        disableCloseOnSelect
        onChange={(event, newValue) => {
          data.onChange(newValue.join(", "));
        }}
        renderTags={() => {
          if (splitValues.length > 1) {
            return `${splitValues.length} Selections`;
          } else {
            const matchingOption = options.find((o) => o.value === value);
            return truncateText(get(matchingOption, "label"), 20);
          }
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              sx={{
                "& fieldset": {
                  border: "0px",
                },
              }}
              placeholder={
                splitValues.length > 0 ? "" : `${selectText} ${data.label}`
              }
            />
          );
        }}
      />
    </div>
  );
};

export default MultiSelect;
