import Icon from "app/components/Icon";
import { colors } from "app/utils/theme";
import styled from "styled-components";

export const HelpCard = ({
  data: { active, title, description, icon, onClick },
}) => (
  <HelpCardContainer onClick={onClick} active={active}>
    <div style={{ height: "25px" }}>
      <Icon data={{ icon, size: 30, color: colors.primary }} />
    </div>
    <div>
      <div
        style={{
          fontSize: "20px",
          fontWeight: 500,
          textAlign: "center",
          color: "var(--text-color)",
        }}
      >
        {title}
      </div>
      <div
        style={{
          fontSize: "16px",
          color: "var(--dark-grey)",
          textAlign: "center",
          margin: "4px 0 0 0",
        }}
      >
        {description}
      </div>
    </div>
  </HelpCardContainer>
);

export const HelpCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(261px, 1fr));
  }
  gap: 20px;
`;

const HelpCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
  background: var(--background);
  box-shadow: rgba(29, 29, 31, 0.05) 0px 4px 5px;
  cursor: pointer;
  transition: box-shadow 0.2s;
  border: 2px solid ${(p) => (p.active ? colors.primary : "var(--divider)")};
  &:hover {
    box-shadow: rgba(29, 29, 31, 0.1) 0px 8px 10px;
  }
`;
