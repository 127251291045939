import * as AdminComponents from "app/adminApp/components";
import * as Components from "app/components";

import InputWrapper from "./InputWrapper";
import { get } from "lodash";

const Field = ({ data }) => {
  const { componentId } = data;

  const wrapperData = {
    id: data.id,
    label: data.label,
    description: data.description,
    hint: data.hint,
    error: data.error,
    columnSpan: get(data, "columnSpan", 2),
    orientation: data.orientation,
    handleFieldClick: data.handleFieldClick,
  };

  const fieldData = {
    ...data,
    key: data.key,
    borderRadius: "10px",
    padding: "15px",
    fontSize: 16,
    width: data.orientation === "horizontal" ? "300px" : "100%",
    minHeight: data.minHeight || "120px",
    height: "50px",
    hideEmptyItem: true,

    // Switch
    ballHeight: "22px",
    backgroundWidth: "48px",
  };

  const FormInput = get(
    { ...Components, ...AdminComponents },
    fieldData.componentId
  );

  if (FormInput) {
    return (
      <InputWrapper data={wrapperData}>
        {data.skeleton && (
          <Components.SkeletonLoader width="100%" height="38px" />
        )}

        {!data.skeleton && (
          <FormInput
            data={{
              ...fieldData,
              border: data.error ? "1px solid red" : data.border,
            }}
          />
        )}
      </InputWrapper>
    );
  }

  // NOT DEFINED
  return <InputWrapper data={wrapperData}>{componentId}</InputWrapper>;
};

export default Field;
