import { Hint, Row, Text } from "app/components";

import { colors } from "app/utils/theme";
import { isAdmin } from "app/utils/utils";
import styled from "styled-components";

const InputWrapper = ({ data, children }) => {
  const {
    id,
    label,
    description,
    hint,
    error,
    orientation = "vertical",
    columnSpan,
    handleFieldClick,
  } = data;

  return (
    <Container
      columnSpan={columnSpan}
      onClick={(e) => {
        if (isAdmin && handleFieldClick) {
          handleFieldClick({ id, target: e.currentTarget });
        }
      }}
      handleFieldClick={isAdmin && handleFieldClick}
    >
      <Wrapper flexDirection={orientation === "vertical" ? "column" : "row"}>
        {(label || description) && (
          <LabelContainer>
            {label && (
              <Row alignItems="center" gap="5px">
                <Text
                  data={{
                    text: label,
                    fontSize: 16,
                    fontWeight: 600,
                    color: "var(--dark-grey)",
                  }}
                />
                {hint && <Hint hint={hint} />}
              </Row>
            )}
            {description && (
              <Text
                data={{
                  text: description,
                  fontSize: 14,
                  fontWeight: 300,
                  color: "var(--light-grey)",
                }}
              />
            )}
          </LabelContainer>
        )}
        <ContentContainer orientation={orientation}>
          {children}
        </ContentContainer>
      </Wrapper>
      {error && (
        <Text
          data={{
            text: error,
            margin: "2px 0 0 0",
            color: "red",
            fontStyle: "bodySm",
          }}
        />
      )}
    </Container>
  );
};

export default InputWrapper;

const ContentContainer = styled.div`
  display: flex;
  justify-content: ${(p) =>
    p.orientation === "vertical" ? "flex-start" : "flex-end"};
  flex: 1;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
`;

const Container = styled.div`
  grid-column: span ${(p) => p.columnSpan};

  ${(p) =>
    p.handleFieldClick &&
    `
    &:hover{
      outline: 1px solid ${colors.primary};
      outline-offset: -2px;
      cursor: pointer;
      border-radius: 4px;
    }
`};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(p) => p.flexDirection || "column"};
  gap: 10px;
  ${(p) =>
    p.flexDirection === "row" &&
    "justify-content: space-between; align-items: center; gap: 20px;"}
`;
