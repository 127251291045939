import { Button, Row, SearchBar, SimpleDropdown } from "app/components";
import { rApp, rSearch, rUser } from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import Cookies from "js-cookie";
import { get } from "lodash";
import logo from "assets/scaale-logo-blue-white.png";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";

const PublicHeader = () => {
  const location = useLocation();
  const path = get(location, "pathname");
  const navigate = useNavigate();
  const user = useRecoilValue(rUser);

  const [search, setSearch] = useRecoilState(rSearch);

  const app = useRecoilValue(rApp);
  const clientLogo = get(app, "navigation_logo") || logo;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container>
      <HeaderRow>
        <Logo src={clientLogo} onClick={() => navigate("/")} />
        <SearchBar
          data={{
            value: search,
            placeholder: "Search tools",
            onChange: (v) => setSearch(v),
            borderRadius: "50px",
            width: "100%",
            background: "var(--background)",
          }}
        />
        {!isMobile && (
          <>
            {user ? (
              <SimpleDropdown
                data={{
                  options: [
                    {
                      icon: "FiLogOut",
                      label: "Logout",
                      onClick: () => {
                        Cookies.remove("accessToken");
                        successNotification("Logged out.");
                        navigate("/login");
                      },
                    },
                    {
                      icon: "FiSettings",
                      label: "Settings",
                      onClick: () => navigate("/admin/settings"),
                    },
                  ],
                  button: {
                    text: `${get(user, "first_name", "")} ${get(
                      user,
                      "last_name",
                      ""
                    )}`,
                    icon: "FiUser",
                    type: "basic",
                    borderRadius: "50px",
                  },
                }}
              />
            ) : (
              <Button
                data={{
                  text: "Login",
                  icon: "FiUser",
                  onClick: () => navigate("/login"),
                  type: "basic",
                  borderRadius: "50px",
                }}
              />
            )}
          </>
        )}
      </HeaderRow>
    </Container>
  );
};

export default PublicHeader;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
  width: 100%;
  @media (max-width: 800px) {
    gap: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px 15px 30px;
`;

const Logo = styled.img`
  width: auto;
  height: 40px;
  max-width: 130px;
  object-fit: contain;
  cursor: pointer;
  @media (max-width: 800px) {
    height: 30px;
    max-width: 100px;
  }
`;
