import { Column, MarkdownEditor, Text } from "app/components";

import { EditableText } from "app/adminApp/components";
import { get } from "lodash";
import { rOutputStepChanges } from "app/utils/recoil";
import { safeArray } from "app/utils/utils";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";

const Output = ({
  stepConfig,
  output,
  setOutput,
  selectedOptions,
  setSelectedOptions,
}) => {
  return (
    <RenderContent
      content={output}
      stepConfig={stepConfig}
      setContent={setOutput}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
    />
  );
};

export default Output;

const renderObject = (item, fieldsMap, editable, onChange) =>
  Object.keys(item).map((key) => {
    const displayType = get(fieldsMap, [key, "displayType"], "text");
    const type = get(fieldsMap, [key, "type"], "text");

    let fontSize = 16;
    let fontWeight = 400;

    if (displayType === "header") {
      fontSize = 22;
      fontWeight = 600;
    }

    // Text Array
    if (type === "text_array") {
      const values = get(item, key, []);
      return (
        <Column gap={"5px"}>
          {values.map((text, index) => (
            <EditableText
              key={index}
              fontSize={fontSize}
              fontWeight={fontWeight}
              onChange={(value) => {
                onChange(value, key, index);
              }}
              value={text}
              editable={editable}
            />
          ))}
        </Column>
      );
    }

    // Text
    return (
      <EditableText
        fontSize={fontSize}
        fontWeight={fontWeight}
        onChange={(value) => {
          onChange(value, key);
        }}
        value={item[key]}
        editable={editable}
      />
    );
  });

const RenderContent = ({
  content,
  stepConfig,
  setContent,
  selectedOptions,
  setSelectedOptions,
}) => {
  const type = get(stepConfig, "type", "text");
  const displayType = get(stepConfig, "displayType", "text");

  const setOutputChanges = useSetRecoilState(rOutputStepChanges);

  const location = useLocation();
  const path = get(location, "pathname", "");
  const isEditor = path.includes("edit_tool");

  let fieldsMap = {};

  const array = safeArray(content);

  // Only render as options if it's set to that type AND if there are valid options. Default to text if not
  const isOptions =
    ["options_single", "options_multiple"].includes(displayType) &&
    array.length > 0;

  if (isOptions) {
    get(stepConfig, "objectFields", []).forEach((field) => {
      fieldsMap[field.key] = field;
    });

    const isMulti = displayType === "options_multiple";

    const onChange = (value, key) => {
      setContent((prev) => {
        const newContent = { ...prev }; // Create a shallow copy of the content object
        newContent[key] = value; // Update the specific key with the new value
        setOutputChanges(true);
        return newContent; // Return the updated object
      });
    };

    return (
      <OptionsContainer>
        {array.map((option, index) => (
          <Option
            key={index}
            onClick={() => {
              if (isMulti) {
                if (selectedOptions.includes(index)) {
                  // Remove from options
                  setSelectedOptions(
                    selectedOptions.filter((o) => o !== index)
                  );
                } else {
                  // Add to options
                  setSelectedOptions([...selectedOptions, index]);
                }
              } else {
                setSelectedOptions([index]);
              }
            }}
            selected={selectedOptions.includes(index)}
          >
            <Column gap={"10px"}>
              {renderObject(option, fieldsMap, false, onChange)}
            </Column>
          </Option>
        ))}
      </OptionsContainer>
    );
  }
  // Text Array
  if (type === "text_array") {
    return (
      <ContentArea>
        <Column gap={"15px"}>
          {content.map((text, index) => (
            <EditableText
              key={index}
              onChange={(value) => {
                setContent(content.map((t, i) => (i === index ? value : t)));
              }}
              value={text}
              editable={true}
            />
          ))}
        </Column>
      </ContentArea>
    );
  }

  // Object Array
  if (type === "object_array") {
    get(stepConfig, "objectFields", []).forEach((field) => {
      fieldsMap[field.key] = field;
    });

    return (
      <ContentArea>
        {content?.map((item, index) => (
          <Section>
            {renderObject(item, fieldsMap, true, (value, key, i) => {
              if (i !== undefined) {
                // Update the specific index within the array
                setContent((prev) => {
                  const newContent = [...prev];
                  newContent[index][key][i] = value;
                  return newContent;
                });
              } else {
                // Update the key directly
                setContent((prev) => {
                  const newContent = [...prev];
                  newContent[index][key] = value;
                  return newContent;
                });
              }
            })}
          </Section>
        ))}
      </ContentArea>
    );
  }

  // Object
  if (type === "object") {
    get(stepConfig, "objectFields", []).forEach((field) => {
      fieldsMap[field.key] = field;
    });
    return (
      <ContentArea>
        {renderObject(content, fieldsMap, true, (value, key) => {
          setContent((prev) => {
            const newContent = { ...prev }; // Create a shallow copy of the content object
            newContent[key] = value; // Update the specific key with the new value
            return newContent; // Return the updated object
          });
        })}
      </ContentArea>
    );
  }

  if (isEditor) {
    return (
      <ContentArea>
        <Text
          data={{
            fontSize: 20,
            text: "This is where the AI-generated content will appear in the live mode. There's no preview here because the content is different each time.",
          }}
        />
      </ContentArea>
    );
  }

  // Default to text
  return (
    <ContentArea>
      {content && (
        <MarkdownEditor
          data={{ value: content, onChange: (value) => setContent(value) }}
        />
      )}
    </ContentArea>
  );
};

const Section = styled.div`
  background: var(--input-background);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ContentArea = styled.div`
  margin-top: 30px;
  background: var(--input-background);
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  gap: 30px;
  display: flex;
  flex-direction: column;
`;

const Option = styled.div`
  background: var(--card-background);
  border-radius: 10px;
  padding: 20px;
  border: 1px solid var(--divider);
  cursor: pointer;
  ${(p) =>
    p.selected &&
    `
  border: 2px solid var(--text-color);
  padding: 19px;
`}
`;

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 40px;
`;
