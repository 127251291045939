import { getPixels } from "app/utils/utils";
import styled from "styled-components";

// Note: If we can get the aspect ratio of a video, we can make this more dynamic

const Vimeo = ({ url, title, width, height, previewWidth }) => {
  return (
    <>
      <StyledIframe
        previewWidth={previewWidth}
        width={width}
        height={height}
        src={url}
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        title={title}
      ></StyledIframe>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </>
  );
};

export default Vimeo;

const StyledIframe = styled.iframe`
  width: ${(p) => getPixels(p.width)};
  height: ${(p) => getPixels(p.height)};
  ${(p) => p.previewWidth < 800 && "width: 100%; height: 200px;"}
  border: 0px;
  float: left;
  @media (max-width: 1000px) {
    height: 250px;
  }
`;
