import { getUrlParameter, parseDateWithFormatObject } from "app/utils/utils";
import { rAppDateFormat, rUser } from "app/utils/recoil";

import { get } from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

const useDynamicText = () => {
  const appDateFormat = useRecoilValue(rAppDateFormat);

  const user = useRecoilValue(rUser);

  const location = useLocation();

  const processDynamicText = (data) => {
    if (!data) return null;

    const { text, context } = data;

    if (!text) return text;

    const pattern = /\{\{\s*(.*?)\s*(?:\|\|\s*(.*?))?\s*\}\}/g;

    const { inputDate = "YYYY-MM-DD" } = appDateFormat || {};

    const timeNow = parseDateWithFormatObject({
      value: moment(),
      formatObject: appDateFormat,
    });

    const ds = {
      time: {
        now: timeNow,
        today: moment().format(inputDate),
      },
      ...context,
      user: {
        ...user,
        ...get(context, "user"),
      },
    };

    return text
      .toString()
      .replace(pattern, function (match, key, defaultValue) {
        let fallback = defaultValue || "";

        const parts = key.split(".").map((p) => (isNaN(p) ? p : parseInt(p)));

        switch (parts[0]) {
          case "windowWidth":
            return window.innerWidth;
          case "params":
            const v = getUrlParameter(parts[1], location);
            return v;
          case "env":
            return `{{ ${key} }}`;
        }

        const v = get(ds, parts, fallback);

        return v;
      });
  };

  return { processDynamicText };
};

export default useDynamicText;
