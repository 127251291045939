import { Column, Icon, Text } from "app/components";
import { getPixels, safeString } from "app/utils/utils";

import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";

const LargeRadioGroup = ({ data }) => {
  const { onChange } = data;

  const value = get(data, "value", null);
  const options = get(data, "options", []).map((o) => ({
    ...o,
    value: safeString(o.value),
  }));
  const isMulti = get(data, "isMulti", false); // Flag to check if it's multi-select (Checkbox vs Radio)
  const orientation = get(data, "layout", "vertical"); // 'vertical', 'horizontal'
  const cardStyle = get(data, "cardStyle", "card"); // 'card', 'noCard'

  const splitValues = value
    ? value.split(",").map((v) => v.trim().toString())
    : [];

  const handleClick = (optionValue) => {
    if (isMulti) {
      // Multi-select logic
      const newValues = splitValues.includes(optionValue)
        ? splitValues.filter((v) => v !== optionValue)
        : [...splitValues, optionValue];

      onChange(newValues.join(", "));
    } else {
      // Single-select logic (radio buttons)
      onChange(optionValue);
    }
  };

  return (
    <Container
      orientation={orientation}
      count={options.length}
      cardStyle={cardStyle}
    >
      {options.map((option, index) => {
        const active = isMulti
          ? splitValues.includes(option.value)
          : value === option.value;

        return (
          <Option
            key={index}
            optionWidth={
              data.optionWidth ? getPixels(data.optionWidth) : "auto"
            }
            orientation={orientation}
            active={active}
            cardStyle={cardStyle}
            onClick={() => handleClick(option.value)}
          >
            <Input
              type={isMulti ? "checkbox" : "radio"}
              checked={active}
              readOnly
            />
            <CustomInput
              orientation={orientation}
              checked={active}
              color={"black"}
              active={active}
              cardStyle={cardStyle}
              type={isMulti ? "checkbox" : "radio"}
            >
              {isMulti && active && (
                <Icon data={{ icon: "FiCheck", color: colors.white }} />
              )}
            </CustomInput>
            <Column gap={"3px"}>
              <Text
                data={{ text: option.label, fontSize: 14, fontWeight: 600 }}
              />
              {option.description && (
                <Text
                  data={{
                    text: option.description,
                    fontSize: 14,
                    fontWeight: 300,
                  }}
                />
              )}
            </Column>
          </Option>
        );
      })}
    </Container>
  );
};

export default LargeRadioGroup;

const Container = styled.div`
  display: flex;
  width: 100%;
  ${(p) =>
    p.orientation === "horizontal"
      ? `
          flex-direction: row;
          gap: ${p.cardStyle === "card" ? "15px" : "40px"};
        `
      : `
          flex-direction: column;
          gap: 10px;
        `}
  flex-wrap: wrap;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(p) => (p.orientation === "horizontal" ? "15px" : "20px")};
  box-sizing: border-box;
  color: ${colors.grey1};
  cursor: pointer;
  border-radius: 10px;
  width: ${(p) => (p.orientation === "horizontal" ? p.optionWidth : "100%")};
  ${(p) =>
    p.cardStyle === "card"
      ? `
    background: #fafafa;
    border: 1px solid #f1f1f1;
    padding: 10px;
    `
      : ``}
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

// Custom styled radio/checkbox using ::before and ::after
const CustomInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(p) =>
    p.cardStyle === "card"
      ? `
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    `
      : `
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    `};

  border-radius: ${(p) => (p.type === "radio" ? "50%" : "4px")};
  background-color: transparent;
  border: 2px solid ${(p) => p.color};

  transition: all 0.3s ease;

  ${(p) => p.type === "checkbox" && p.active && `background-color: ${p.color};`}
  ${(p) =>
    p.type === "radio" &&
    `
    &::before {
        content: "";
        position: absolute;
        width: ${p.cardStyle === "card" ? "10px" : "8px"};
        height: ${p.cardStyle === "card" ? "10px" : "8px"};
        background-color: ${p.active ? p.color : "transparent"};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        opacity: ${p.checked ? "1" : "0"};
        transition: opacity 0.2s ease-in-out;
    }
  `}
`;
