import { Icon } from "app/components";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { getPixels } from "app/utils/utils";
import { rApp } from "app/utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

const Progress = ({ data }) => {
  const { steps, isMobile } = data;

  const finalOrientation = isMobile ? "horizontal" : "vertical";

  return (
    <Container orientation={finalOrientation}>
      {steps.map((step, index) => {
        return (
          <>
            <Step
              key={index}
              isLast={index === steps.length - 1}
              active={step.active}
              labelFontSize={get(data, "labelFontSize")}
              descriptionFontSize={get(data, "descriptionFontSize")}
              data={step}
              orientation={finalOrientation}
              hideTextHorizontal={
                finalOrientation === "horizontal"
                  ? get(data, "hideTextHorizontal", false)
                  : false
              }
            />
          </>
        );
      })}
    </Container>
  );
};

export default Progress;

const Step = ({
  data,
  active,
  isLast,
  orientation,
  hideTextHorizontal,
  labelFontSize,
  descriptionFontSize,
}) => {
  const { label, description } = data;

  const app = useRecoilValue(rApp);

  const primaryColor = get(app, "primary_color", colors.primary);

  const icon = active ? "FaDotCircle" : "FaRegDotCircle";

  return (
    <StepContainer orientation={orientation} onClick={data.onClick}>
      {!isLast && (
        <Line
          color={active ? primaryColor : "var(--light-grey)"}
          orientation={orientation}
        />
      )}
      <div style={{ minWidth: "20px", zIndex: 100 }}>
        <Icon
          data={{
            icon,
            color: active ? primaryColor : "var(--light-grey)",
            size: 24,
          }}
        />
      </div>
      {!hideTextHorizontal && (
        <div>
          <Label
            fontSize={labelFontSize}
            color={active ? "var(--text-color)" : "var(--light-grey)"}
          >
            {label}
          </Label>
          {/* {description && (
            <Description
              fontSize={descriptionFontSize}
              color={active ? primaryColor : "var(--light-grey)"}
            >
              {description}
            </Description>
          )} */}
        </div>
      )}
    </StepContainer>
  );
};

const Line = styled.div`
  position: absolute;
  background-color: ${(p) => p.color};
  z-index: 90;
  ${(p) =>
    p.orientation === "vertical"
      ? `
      width: 2px;
      top: 16px;
      bottom: 0px;
      left: 11px;
    `
      : `
      height: 2px;
      left: calc(50% + 11px);
      right: calc(-50% + 11px);
      bottom: 14px;
    `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: ${(p) => (p.orientation === "vertical" ? "column" : "row")};
  min-width: 250px;
`;

const Label = styled.div`
  font-size: ${(p) => p.fontSize || "20px"};
  font-weight: 500;
  color: ${(p) => p.color};
`;

const Description = styled.div`
  font-size: ${(p) => p.fontSize || "16px"};
  font-weight: 300;
  color: ${(p) => p.color};
`;

const StepContainer = styled.div`
  display: flex;
  position: relative;

  ${(p) => p.onClick && "cursor: pointer;"}

  ${(p) =>
    p.orientation === "vertical"
      ? `
      padding-bottom: 25px;
      gap: 15px;
    `
      : `
      flex-direction: column-reverse;
      align-items: center;
      gap: 5px;
      flex: 1;
      text-align: center;
    `}
`;
