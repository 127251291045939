import { rConfirmationModalData, rToolChanges } from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import curatorIcon from "assets/scaale-icon-blue.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const BackSection = () => {
  const pageChanges = useRecoilValue(rToolChanges);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const navigate = useNavigate();

  return (
    <Container
      onClick={() => {
        const back = () => {
          navigate("/tools/my-tools");
        };

        if (pageChanges) {
          setConfirmationModalData({
            title: "You have unsaved changes",
            confirmText: "Leave without saving",
            text: "Are you sure you want to leave this page without saving? Your changes will be lost.",
            confirm: back,
          });
        } else {
          back();
        }
      }}
    >
      <ScaaleIcon src={curatorIcon} />
      {/* <Rotate>
        <Icon
          data={{
            icon: "FiLogOut",
            size: 20,
            hover: true,
            color: colors.grey3,
          }}
        />
      </Rotate> */}
    </Container>
  );
};

export default BackSection;

const ScaaleIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 12px 0 12px;
  margin: 0 15px 0 0;
  border-right: 1px solid var(--divider);
  cursor: pointer;
  opacity: 1;
  &:hover {
    opacity: 0.85;
  }
`;

const Rotate = styled.div`
  transform: rotate(180deg);
`;
