import * as React from "react";

import { Row, Select } from "app/components";

import { get } from "lodash";

// Function to validate time format
const getValidTime = (time) => {
  const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  return timeRegex.test(time) ? time : "00:00";
};

const TimePicker = ({ data }) => {
  // Added interval prop with default value
  // Use getValidTime to validate the time
  const value = getValidTime(get(data, "value", "00:00"));
  const onChange = get(data, "onChange");
  const interval = get(data, "interval", 5);
  const showAmPm = get(data, "showAmPm", false);
  // Parse the value into hours and minutes
  const [hours, minutes] = value.split(":");

  const handleHoursChange = (newHours) => {
    let adjustedHours = parseInt(newHours, 10);
    if (showAmPm) {
      const isPm = initialPeriod === "PM";
      if (isPm && adjustedHours < 12) {
        adjustedHours += 12;
      } else if (!isPm && adjustedHours === 12) {
        adjustedHours = 0;
      }
    }
    onChange(`${adjustedHours.toString().padStart(2, "0")}:${minutes}`);
  };

  const handleMinutesChange = (newMinutes) => {
    onChange(`${hours}:${newMinutes}`);
  };

  const handleAmPmChange = (period) => {
    let newHours = parseInt(hours, 10);
    if (period === "PM" && newHours < 12) {
      newHours += 12;
    } else if (period === "AM" && newHours >= 12) {
      newHours -= 12;
    }
    onChange(`${newHours.toString().padStart(2, "0")}:${minutes}`);
  };

  // Determine initial AM/PM value
  const initialPeriod = parseInt(hours, 10) >= 12 ? "PM" : "AM";

  // Generate options for hours
  const hourOptions = showAmPm
    ? Array.from({ length: 12 }, (_, i) => {
        const value = (i + 1).toString().padStart(2, "0");
        return { label: value, value: value };
      })
    : Array.from({ length: 24 }, (_, i) => {
        const value = i.toString().padStart(2, "0");
        return { label: value, value: value };
      });

  // Generate options for minutes based on the interval
  const minuteOptions = Array.from({ length: 60 / interval }, (_, i) => {
    const value = (i * interval).toString().padStart(2, "0");
    return { label: value, value: value };
  });

  return (
    <Row gap="10px">
      <Select
        data={{
          onChange: handleHoursChange,
          hideEmptyItem: true,
          value: showAmPm
            ? (parseInt(hours, 10) % 12 || 12).toString().padStart(2, "0")
            : hours,
          width: "80px",
          options: hourOptions,
        }}
      />
      <Select
        data={{
          onChange: handleMinutesChange,
          hideEmptyItem: true,
          value: minutes,
          width: "80px",
          options: minuteOptions,
        }}
      />
      {showAmPm && (
        <Select
          data={{
            onChange: handleAmPmChange,
            hideEmptyItem: true,
            value: initialPeriod,
            width: "80px",
            options: [
              { label: "AM", value: "AM" },
              { label: "PM", value: "PM" },
            ],
          }}
        />
      )}
    </Row>
  );
};

export default TimePicker;
