import { Icon, Row, Text } from "app/components";

import { colors } from "app/utils/theme";
import styled from "styled-components";
import { useState } from "react";

const Tile = ({
  label,
  accent,
  onClick,
  icon,
  iconColor,
  onDelete,
  onDuplicate,
  className,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  return (
    <TileContainer
      accent={accent}
      className={className}
      onClick={onClick}
      onMouseEnter={() => {
        if (!showDelete) {
          setShowDelete(true);
        }
      }}
      onMouseLeave={() => {
        if (showDelete) {
          setShowDelete(false);
        }
      }}
    >
      {icon && (
        <IconWrapper color={iconColor}>
          <Icon
            data={{
              icon: icon,
              color: "var(--text-color)",
              size: 19,
              marginTop: 1,
            }}
          />
        </IconWrapper>
      )}
      <Row justifyContent="space-between" alignItems="center" width="100%">
        <Text
          data={{
            cursor: "pointer",
            text: label,
            fontSize: 14,
            fontWeight: 500,
            color: "var(--text-color)",
          }}
        />
        {showDelete && (
          <Row gap="10px">
            {onDuplicate && (
              <Icon
                data={{
                  icon: "FiCopy",
                  hover: true,
                  onClick: (e) => {
                    e.stopPropagation();
                    onDuplicate();
                  },
                  color: "var(--light-grey)",
                  size: 18,
                }}
              />
            )}
            {onDelete && (
              <Icon
                data={{
                  icon: "FiTrash",
                  hover: true,
                  onClick: (e) => {
                    e.stopPropagation();
                    onDelete();
                  },
                  color: "var(--light-grey)",
                  size: 18,
                }}
              />
            )}
          </Row>
        )}
      </Row>
    </TileContainer>
  );
};

export default Tile;

const IconWrapper = styled.div`
  background: ${(p) => p.color};
  padding: 5px;
  border-radius: 6px;
  min-width: 25px;
  max-width: 25px;
  min-height: 25px;
  max-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TileContainer = styled.div`
  border: 1px solid var(--divider);
  padding: 9px 12px 9px 12px;
  background: var(--input-background);
  border-radius: 10px;
  ${(p) =>
    p.accent && `border-left: 5px solid var(--light-grey); padding-left: 8px;`}
  cursor: pointer;
  display: flex;
  gap: 12px;
  align-items: center;
  &:hover {
    background: var(--divider);
  }
`;
