import { Column } from "app/components";
import Field from "./components/Field";
import { fullWidthFormFields } from "app/utils/utils";
import { get } from "lodash";
import styled from "styled-components";

const Input = ({ previewWidth, stepConfig, input, setInput }) => {
  const inputs = get(stepConfig, "inputs", []);

  return (
    <RenderGroups
      groups={get(stepConfig, "groups", [])}
      inputs={inputs}
      input={input}
      setInput={setInput}
      previewWidth={previewWidth}
    />
  );
};

export default Input;

const RenderGroups = ({
  groups = [],
  inputs = [],
  input,
  setInput,
  previewWidth,
}) => {
  const noGroupFields = inputs.filter((f) => {
    return !groups.some((g) =>
      get(g, "fields", "")
        .split(",")
        .map((k) => k.trim())
        .includes(f.key)
    );
  });

  return (
    <Column gap="30px">
      {noGroupFields.length > 0 && (
        <RenderGroup
          group={{}}
          fields={noGroupFields}
          input={input}
          setInput={setInput}
          previewWidth={previewWidth}
        />
      )}
      {groups.map((g) => {
        const groupFieldIds = get(g, "fields", "")
          .split(",")
          .map((k) => k.trim());
        const groupFields = inputs.filter((input) =>
          groupFieldIds.includes(input.key)
        );

        return (
          <RenderGroup
            key={g.id}
            group={g}
            fields={groupFields}
            input={input}
            setInput={setInput}
            previewWidth={previewWidth}
          />
        );
      })}
    </Column>
  );
};

const RenderGroup = ({ group, fields = [], input, setInput, previewWidth }) => {
  const gridColumns = 2;

  return (
    <div>
      {(group.label || group.description) && (
        <GroupHeader>
          <GroupLabel>{group.label}</GroupLabel>
          <GroupDescription>{group.description}</GroupDescription>
        </GroupHeader>
      )}
      <GroupFieldsContainer gridColumns={gridColumns}>
        {fields.map((field, fieldIndex) => {
          const componentId = get(field, "componentId", "Input");

          // Handle Field Sizing
          const forceFullWidth =
            fullWidthFormFields.includes(componentId) || previewWidth < 768;
          //   Force large fields to be full width always

          const columnSpan = forceFullWidth
            ? gridColumns
            : get(field, "columnSpan", 2);

          const value = get(input, field.key, "");

          return (
            <Field
              key={fieldIndex}
              data={{
                ...field,
                componentId,
                background: "var(--card-background)",
                value,
                columnSpan,
                onChange: (value) => {
                  setInput({
                    ...input,
                    [field.key]: value,
                  });
                },
              }}
            />
          );
        })}
      </GroupFieldsContainer>
    </div>
  );
};

const GroupHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
`;

const GroupLabel = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: var(--text-color);
`;

const GroupDescription = styled.div`
  color: var(--light-grey);
`;

const GroupFieldsContainer = styled.div`
  gap: 15px;
  display: grid;
  grid-template-columns: repeat(${(p) => p.gridColumns}, 1fr);
  grid-template-rows: auto;
  width: 100%;
`;
