import { Icon, Row, Text } from "app/components";

import styled from "styled-components";

const BackNav = ({ onClick, text }) => {
  return (
    <Row
      alignItems="center"
      gap="10px"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <Icon
        data={{
          icon: "FiArrowLeft",
          color: "var(--light-grey)",
          size: 22,
          hover: true,
        }}
      />
      <Text
        data={{
          text,
          color: "var(--light-grey)",
          fontSize: 20,
          fontWeight: "500",
          cursor: "pointer",
        }}
      />
    </Row>
  );
};

export default BackNav;

export const BackButton = ({
  onClick,
  color,
  text = "Back",
  margin,
  hideText,
}) => {
  return (
    <BackRow onClick={onClick} margin={margin}>
      <IconWrapper>
        <Icon icon={"FiArrowLeft"} color={color || "#6E7175"} hover size={18} />
      </IconWrapper>
      {!hideText && (
        <Text
          data={{
            value: text,
            size: 18,
            fontWeight: "600",
            cursor: "pointer",
          }}
        />
      )}
    </BackRow>
  );
};

const BackRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  margin: ${(p) => p.margin || "0px"};
  width: fit-content;
  &:hover {
    opacity: 0.8;
  }
`;

const IconWrapper = styled.div`
  background: ${(p) => p.background || "#e4e5e7"};
  border-radius: 4px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
