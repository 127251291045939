import {
  Button,
  Hint,
  Icon,
  Input,
  Label,
  Modal,
  Row,
  Text,
} from "app/components";
import { colors, spacing } from "app/utils/theme";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, startCase, upperCase } from "lodash";
import {
  rConfirmationModalData,
  rOrganization,
  rSetOrganization,
  rSubscription,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AdminWrapper from "../home/AdminWrapper";
import { apiRequest } from "app/utils/apiRequests";
import { getUrlParameter } from "app/utils/utils";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const basic = {
  name: "Basic",
  key: "basic",
  price: 20,
  frequency: "/mo",
  description: "For small teams and startups",
  features: [
    { icon: "FiGrid", text: "1 Published App" },
    { icon: "FiEdit", text: "1 Editor" },
    { icon: "FiUser", text: "50 Private Users" },
    { icon: "FiUser", text: "Unlimited Public Users" },
    { icon: "HiSparkles", text: "500 Monthly AI Tokens" },
    { icon: "TbCircleLetterB", text: "Custom Domain & Branding" },
  ],
};

const growth = {
  name: "Growth",
  key: "growth",
  description: "For growing businesses",
  price: 40,
  frequency: "/mo",
  features: [
    { icon: "FiGrid", text: "5 Published Apps" },
    { icon: "FiEdit", text: "5 Editors" },
    { icon: "FiUser", text: "200 Private Users" },
    { icon: "FiUser", text: "Unlimited Public Users" },
    { icon: "HiSparkles", text: "2,000 Monthly AI Tokens" },
    { icon: "TbCircleLetterB", text: "Custom Domain & Branding" },
    { icon: "FiMessageCircle", text: "Priority Support" },
  ],
};

const team = {
  name: "Team",
  key: "team",
  description: "For larger teams and enterprises",
  price: 100,
  frequency: "/mo",
  features: [
    { icon: "FiGrid", text: "20 Published Apps" },
    { icon: "FiEdit", text: "Unlimited Editors" },
    { icon: "FiUser", text: "Unlimited Private Users" },
    { icon: "FiUser", text: "Unlimited Public Users" },
    { icon: "HiSparkles", text: "5,000 Monthly AI Tokens" },
    { icon: "TbCircleLetterB", text: "Custom Domain & Branding" },
    { icon: "FiMessageCircle", text: "Priority Support" },
    { icon: "FiLifeBuoy", text: "Personalized Onboarding" },
  ],
};

const Billing = () => {
  const [tempDiscountRate, setTempDiscountRate] = useState(null);

  const organization = useRecoilValue(rOrganization);

  const setOrganization = useSetRecoilState(rSetOrganization);

  const savedCouponCode = get(organization, "coupon_code");

  const [isCancelling, setIsCancelling] = useState(false);

  const [isFetchingPlan, setIsFetchingPlan] = useState(null);

  const [newPlan, setNewPlan] = useState(null);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const location = useLocation();

  const action = getUrlParameter("action", location);
  const result = getUrlParameter("result", location);
  const sessionId = getUrlParameter("session_id", location);
  const planType = getUrlParameter("plan_type", location);

  const cardSaved = get(organization, "stripe_id");

  const subscription = useRecoilValue(rSubscription);

  const currentPlan = get(subscription, "plan");

  const discountRate = get(subscription, "discount_rate") || tempDiscountRate;

  useEffect(() => {
    // mixpanel.track("Billing Page View");
  }, []);

  const cancelSubscription = (isSavingSet = null) => {
    // mixpanel.track("Cancel Subscription", { type: planType });
    setIsCancelling(true);
    isSavingSet?.(true);
    apiRequest
      .post("/stripe/cancel/")
      .then(() => {
        setOrganization({
          subscription: null,
        });

        successNotification("Plan Cancelled");
        setIsCancelling(false);
      })
      .catch((error) => {
        isSavingSet?.(false);
      });
  };

  const createSub = (planType) => {
    if (cardSaved) {
      createSubscription(planType);
    } else {
      createCardSession(planType);
    }
  };

  const createCardSession = (planType) => {
    setIsFetchingPlan(planType);
    apiRequest
      .get(`/stripe/session/?plan_type=${planType}`)
      .then((response) => {
        const sessionUrl = get(response, ["data", "session", "url"]);
        window.location.href = sessionUrl;
        setIsFetchingPlan(null);
      });
  };

  const createSubscription = (planType, isSavingSet = null) => {
    // mixpanel.track("Create Subscription", { type: planType });
    setIsFetchingPlan(planType);
    isSavingSet?.(true);
    apiRequest
      .post(`/stripe/subscription/`, {
        plan_type: planType,
      })
      .then((response) => {
        const sub = get(response, ["data", "subscription"]);
        setOrganization({
          subscription: sub,
        });
        successNotification("Plan Changed Successfully");
        setIsFetchingPlan(null);
      })
      .catch((error) => {
        isSavingSet?.(false);
      });
  };

  useEffect(() => {
    if (action === "payment" && result === "success" && sessionId && planType) {
      setIsFetchingPlan(planType);
      apiRequest
        .post("/stripe/auth/", {
          session_id: sessionId,
          plan_type: planType,
        })
        .then((response) => {
          const cardSaved = get(response, ["data", "card_saved"]);
          const sub = get(response, ["data", "subscription"]);
          if (cardSaved) {
            // UPDATE STATE TO SHOW CARD IS SAVED
            setOrganization({
              stripe_id: true,
              subscription: sub,
            });
            successNotification("Plan Changed Successfully");
          }
          setIsFetchingPlan(null);
        });
    }
  }, []);

  const plans = [basic, growth, team];

  return (
    <AdminWrapper contentWidth="100%">
      {newPlan && (
        <PlanChangeModal
          currentPlan={currentPlan}
          newPlan={newPlan}
          hide={() => setNewPlan(null)}
          createSub={createSub}
          isFetchingPlan={isFetchingPlan}
          savedCouponCode={savedCouponCode}
          setTempDiscountRate={setTempDiscountRate}
        />
      )}

      {!currentPlan && (
        <UpgradeBanner>
          You are on the free setup plan. Upgrade to publish your app.
        </UpgradeBanner>
      )}
      <Label style={{ margin: "0 0 30px 0" }}>Billing</Label>

      <Row gap={spacing.s5}>
        <PlansContainer currentPlan={currentPlan}>
          {plans.map((plan) => (
            <Plan
              discountRate={discountRate}
              plan={plan}
              currentPlan={currentPlan}
              setNewPlan={setNewPlan}
              isFetchingPlan={isFetchingPlan}
            />
          ))}
        </PlansContainer>

        {currentPlan && (
          <div style={{ minWidth: "250px" }}>
            <CurrentPlanContainer>
              <PlanNameContainer>
                <Text
                  data={{
                    text: "Current Plan",
                    fontStyle: "headingMd",
                    color: colors.grey3,
                  }}
                />
                <Text
                  data={{
                    text: startCase(currentPlan),
                    fontStyle: "heading2xl",
                  }}
                />
                <Text
                  data={{
                    margin: "10px 0 0 0",
                    text:
                      subscription.amount === 0
                        ? "Free"
                        : `$${subscription.amount} ${
                            subscription.frequency === "one_time"
                              ? "Lifetime deal"
                              : subscription.frequency
                          }`,
                    fontStyle: "headingMd",
                    color: colors.grey3,
                  }}
                />
              </PlanNameContainer>

              <CurrentPlanFeature
                label="Apps"
                value={renderValue(get(subscription, "app_limit"))}
                hint="An App in Scaale is a distinct project with its own pages, users, navigation and domain name. Multiple apps can be used if you need to launch multiple separate projects under one company account."
              />
              <CurrentPlanFeature
                label="Admins"
                value={renderValue(get(subscription, "admin_user_limit"))}
                hint="Admins have full access to all data with permission to create, modify and delete pages, and manage users"
              />
              <CurrentPlanFeature
                label="Users"
                value={renderValue(get(subscription, "user_limit"))}
                hint="Users are clients, customers, or partners who can login to your portal with an email and password. This is an end-user, so they do not have access to your admin or any editing capabilities other than what you've built into your portal."
              />
              <CurrentPlanFeature
                label="AI Token Limit"
                value={renderValue(get(subscription, "ai_requests_limit"))}
                hint="AI Tokens are used to generate pages, spreadsheets, and in the OpenAI action. Each individual usage of AI Generation consumes 1 token. Tokens replenish each month."
              />
              <CurrentPlanFeature
                label="AI Token Usage"
                value={get(organization, "ai_usage", 0)}
                hint="This shows your current monthly usage of the AI tokens"
              />
            </CurrentPlanContainer>
            {["basic", "growth", "team"].includes(currentPlan) && (
              <Button
                data={{
                  text: "Cancel Subscription",
                  margin: "30px 0 0 0",
                  size: "large",
                  type: "basic",
                  isFetching: isCancelling,
                  onClick: () =>
                    setConfirmationModalData({
                      title: "Cancel Subscription?",
                      text: "Are you sure you want to cancel your subscription? This will unpublished your live app and prevent any users from accessing it.",
                      confirm: cancelSubscription,
                    }),
                }}
              />
            )}
            {currentPlan && (
              <Button
                data={{
                  text: "Buy More AI Tokens",
                  margin: "20px 0 0 0",
                  onClick: () =>
                    window.open("https://buy.stripe.com/dR69BuesJdWcf9maFl"),
                }}
              />
            )}
          </div>
        )}
      </Row>
    </AdminWrapper>
  );
};

export default Billing;

export const renderValue = (value) => {
  if (value) {
    return value.toLocaleString("en-US");
  }
  return "Unlimited";
};

const PlanChangeModal = ({
  currentPlan,
  newPlan,
  hide,
  createSub,
  isFetchingPlan,
  savedCouponCode,
  setTempDiscountRate,
}) => {
  const planMap = {
    basic,
    growth,
    team,
  };

  const planInfo = get(planMap, newPlan);

  const title = currentPlan
    ? `Confirm changing your plan to the Scaale ${startCase(newPlan)} plan?`
    : `Confirm subscribing to the Scaale ${startCase(newPlan)} plan?`;

  return (
    <Modal
      hide={hide}
      header={{
        title,
      }}
    >
      <Text
        data={{
          text: `The ${startCase(newPlan)} plan includes:`,
          textAlign: "center",
          fontStyle: "headingSm",
        }}
      />
      <ul>
        {planInfo.features.map((f, i) => (
          <li key={i}>
            <Text
              data={{
                text: f.text,
                fontStyle: "bodyMd",
                color: colors.grey4,
              }}
            />
          </li>
        ))}
      </ul>
      <Row gap="15px" margin="20px 0 0 0">
        <Button
          data={{
            isFetching: isFetchingPlan,
            text: "Confirm Plan Change",
            onClick: (e) => {
              e.stopPropagation();
              createSub(newPlan);
            },
          }}
        />
        <Button
          data={{
            text: "Cancel",
            type: "basic",
            onClick: hide,
          }}
        />
      </Row>

      {!currentPlan && !savedCouponCode && (
        <CouponCode setTempDiscountRate={setTempDiscountRate} />
      )}
    </Modal>
  );
};

const CouponCode = ({ setTempDiscountRate }) => {
  const setOrganization = useSetRecoilState(rOrganization);

  const [showCouponInput, setShowCouponInput] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);

  return (
    <div>
      {showCouponInput ? (
        <Text
          data={{
            text: "If you have a coupon code, enter it here:",
            cursor: "pointer",
            margin: "15px 0 0 0",
          }}
        />
      ) : (
        <Text
          data={{
            text: "Have a coupon code?",
            color: colors.primary,
            cursor: "pointer",
            margin: "15px 0 0 0",
            onClick: () => setShowCouponInput(true),
          }}
        />
      )}
      {showCouponInput && (
        <Row margin="10px 0 0 0" alignItems="center" gap="10px">
          <Input
            data={{
              width: "200px",
              placeholder: "Enter coupon code",
              value: couponCode,
              onChange: (v) => setCouponCode(v),
            }}
          />
          <Button
            data={{
              text: "Apply",
              isFetching: isApplyingCoupon,
              onClick: () => {
                setIsApplyingCoupon(true);
                // Apply coupon code
                apiRequest
                  .post("/apply_coupon/", {
                    coupon_code: couponCode,
                  })
                  .then((r) => {
                    const err = get(r, ["data", "error"]);

                    setIsApplyingCoupon(false);
                    if (err) {
                      errorNotification("Invalid Coupon Code");
                    } else {
                      const discountRate = get(r, ["data", "discount_rate"]);
                      setTempDiscountRate(discountRate);

                      successNotification("Coupon Code Applied");

                      setOrganization({
                        coupon_code: couponCode,
                      });
                    }
                  });
              },
              disabled: !couponCode,
            }}
          />
        </Row>
      )}
    </div>
  );
};

const Plan = ({
  plan,
  currentPlan,
  setNewPlan,
  isFetchingPlan,
  discountRate = null,
}) => {
  const active = currentPlan === plan.key;

  // Get button text
  let buttonText = "Upgrade Now";
  if (active) {
    buttonText = "Current Plan";
  }

  let price = plan.price;

  if (discountRate) {
    price = price * (1 - discountRate);
  }

  return (
    <PlanContainer active={active}>
      <div>
        <Row alignItems="center" gap="10px">
          <Text data={{ text: plan.name, fontStyle: "headingLg" }} />
        </Row>
        <Row alignItems="flex-end" gap="5px" margin="20px 0 0 0">
          <Text data={{ text: `$${price}`, fontStyle: "heading4xl" }} />
          <Text
            data={{
              text: plan.frequency,
              fontStyle: "headingMd",
              color: colors.grey3,
              margin: "0 0 7px 0",
            }}
          />
        </Row>

        <Button
          data={{
            text: buttonText,
            isFetching: isFetchingPlan === plan.key,
            disabled: currentPlan === plan.key,
            onClick: () => setNewPlan(plan.key),
            margin: "20px 0 20px 0",
          }}
        />

        {plan.features.map((f, i) => (
          <Row key={i} alignItems="center" gap="8px" margin="10px 0 0 0">
            <Icon data={{ icon: f.icon, color: colors.grey3, size: 18 }} />
            <Text
              data={{
                text: f.text,
                fontStyle: "bodyMd",
                color: colors.grey3,
              }}
            />
          </Row>
        ))}
      </div>
    </PlanContainer>
  );
};

export const CurrentPlanFeature = ({ label, value, hint }) => {
  return (
    <CurrentPlanFeatureContainer>
      <Row justifyContent="space-between" alignItems="center">
        <Row alignItems="center" gap="2px">
          <Text
            data={{
              text: upperCase(label),
              fontStyle: "headingSm",
              color: colors.grey3,
              margin: "0 0 5px 0",
            }}
          />
          <Hint hint={hint} margin="" />
        </Row>
        <Text
          data={{ text: value, fontStyle: "headingMd", color: colors.grey4 }}
        />
      </Row>
    </CurrentPlanFeatureContainer>
  );
};

const CurrentPlanContainer = styled.div`
  border: 1px solid ${colors.divider};
  border-radius: 10px;
  background: white;
`;

const PlansContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 15px;
  width: 100%;
  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const PlanContainer = styled.div`
  padding: 20px;
  display: flex;
  gap: 15px;
  flex: 1;
  flex-basis: 320px;
  background: white;
  border: 1px solid ${colors.divider};
  border-radius: 10px;
`;

const PlanNameContainer = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
`;

const CurrentPlanFeatureContainer = styled.div`
  border-top: 1px solid ${colors.divider};
  padding: 15px;
`;

const UpgradeBanner = styled.div`
  background: transparent;
  border: 1px solid ${colors.divider};
  color: ${colors.grey4};
  padding: 15px;
  font-weight: 500;
  border-radius: 10px;
  margin: 0 0 20px 0;
  font-size: 14px;
  width: 100%;
`;
