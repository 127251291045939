import { Breadcrumb } from "app/components";
import styled from "styled-components";

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--divider);
  padding: 12px 15px 12px 15px;
`;

const Crumbs = ({ items }) => {
  return (
    <HeaderRow>
      <Breadcrumb items={items} fontSize={24} />
    </HeaderRow>
  );
};

export default Crumbs;
