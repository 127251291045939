import { Icon, SimpleDropdown, SkeletonLoader, Text } from "app/components";
import { colors, spacing } from "app/utils/theme";
import { get, snakeCase, startCase } from "lodash";
import { getBadgeColorMap, getBadgesArray } from "app/components/Badge";
import {
  getLinkOnClick,
  parseBoolean,
  parseDateWithFormatObject,
  truncateText,
} from "app/utils/utils";
import {
  rActiveEditField,
  rAppDateFormat,
  rTranslations,
  rUniqueValuesMap,
} from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { Row } from "app/components";
import Thumbnail from "app/components/Thumbnail";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

const Table = ({ data }) => {
  let { rows, columns, noResultsText, isFetching } = data;

  const translations = useRecoilValue(rTranslations);

  const setActiveEditField = useSetRecoilState(rActiveEditField);

  const uniqueValuesMap = useRecoilValue(rUniqueValuesMap);

  const darkMode = false;

  const rowActions = get(data, "rowActions", []) || [];

  const sorting = null;

  const showAlternatingColors = false;

  const rowHeight = get(data, "rowHeight") === "condensed" ? "44px" : "56px";

  const borderStyle = get(data, "borderStyle", "solid");

  return (
    <ContentContainer>
      <TableContainer>
        {columns.map((column, colIndex) => {
          // Assemble badge color map based on the unique values for this column
          const badgeColorMap =
            column.type === "badge" &&
            getBadgeColorMap({
              customColors: get(column, "badgeColors", []),
              uniqueValues: get(uniqueValuesMap, column.id, []),
            });

          return (
            <ColumnContainerNew
              key={colIndex}
              allowColumnClick={data.allowColumnClick}
            >
              <CellContainer
                darkMode={darkMode}
                height={rowHeight}
                borderStyle={borderStyle}
              >
                {isFetching ? (
                  <SkeletonLoader height="20px" width="60%" />
                ) : (
                  <Header
                    text={column.label || startCase(column.id)}
                    onClick={() =>
                      data.headerOnClick ? data.headerOnClick(column.id) : null
                    }
                  />
                )}
                {sorting && sorting.split("___")[0] === column.id && (
                  <Icon
                    data={{
                      margin: "0 0 0 4px",
                      color: "var(--dark-grey)",
                      hover: true,
                      icon: sorting.includes("___asc")
                        ? "FiChevronUp"
                        : "FiChevronDown",
                    }}
                  />
                )}
              </CellContainer>
              {isFetching &&
                [1, 2, 3, 4, 5].map((r, i) => {
                  const isOdd = i % 2 === 0;
                  return (
                    <CellContainer
                      darkMode={darkMode}
                      height={rowHeight}
                      showBorder={!showAlternatingColors}
                      borderStyle={borderStyle}
                      background={
                        showAlternatingColors && isOdd && colors.grey1
                      }
                      key={i}
                    >
                      <SkeletonLoader height="20px" widthRange={[100, 250]} />
                    </CellContainer>
                  );
                })}

              {!isFetching &&
                rows.map((r, i) => {
                  const isOdd = i % 2 === 0;
                  return (
                    <CellContainer
                      darkMode={darkMode}
                      height={rowHeight}
                      showBorder={!showAlternatingColors}
                      borderStyle={borderStyle}
                      background={
                        showAlternatingColors && isOdd && colors.grey1
                      }
                      key={i}
                      onClick={r.onClick}
                    >
                      <Cell
                        darkMode={darkMode}
                        cellIndex={i}
                        column={column}
                        value={get(r, column.id)}
                        badgeColorMap={badgeColorMap}
                        isCondensed={get(data, "rowHeight") === "condensed"}
                      />
                    </CellContainer>
                  );
                })}
            </ColumnContainerNew>
          );
        })}
        {rowActions.length > 0 && (
          <ColumnContainerNew>
            <CellContainer
              alignEnd={false}
              height={rowHeight}
              darkMode={darkMode}
              borderStyle={borderStyle}
            >
              <Header text={get(translations, "actions", "Actions")} />
            </CellContainer>

            {rows.map((r) => {
              return (
                <CellContainer
                  alignEnd={false}
                  height={rowHeight}
                  darkMode={darkMode}
                  borderStyle={borderStyle}
                >
                  <SimpleDropdown
                    data={{
                      options: get(r, "actions", []),
                      icon: {
                        icon: "FiMoreHorizontal",
                        size: 25,
                        hover: true,
                        color: colors.grey3,
                      },
                    }}
                  />
                </CellContainer>
              );
            })}
          </ColumnContainerNew>
        )}
        {!isFetching && rows.length === 0 && (
          <NoContent>{noResultsText || "No results"}</NoContent>
        )}
      </TableContainer>
    </ContentContainer>
  );
};

export default Table;

const Header = ({ text, onClick }) => (
  <Text
    data={{
      text: text,
      fontStyle: "headingSm",
      cursor: "pointer",
      color: colors.darkModeLightText,
      allowSelect: true,
      onClick,
    }}
  />
);

const Cell = ({
  column,
  value,
  badgeColorMap,
  cellIndex,
  isCondensed,
  darkMode,
}) => {
  const type = column.type;

  const appDateFormat = useRecoilValue(rAppDateFormat);

  if (type === "image") {
    return (
      <Thumbnail
        src={value}
        size="small"
        darkMode={darkMode}
        forceSize={isCondensed ? "30px" : null}
      />
    );
  } else if (type === "date") {
    const df = { ...appDateFormat, ...get(column, "dateFormat", {}) };
    return (
      <div style={{ color: "var(--text-color)" }}>
        {parseDateWithFormatObject({
          value,
          formatObject: df,
        })}
      </div>
    );
  } else if (type === "boolean") {
    const parsedValue = parseBoolean(value);

    return (
      <BooleanWrapper value={parsedValue}>
        <Icon
          data={{
            icon: parsedValue ? "FiCheck" : "FiX",
            size: 14,
            hover: true,
            color: parsedValue ? "#2F7D61" : "#CB401B",
          }}
        />
      </BooleanWrapper>
    );
  } else if (type === "badge") {
    return (
      <Row gap="5px">
        {getBadgesArray({
          value,
          badgeColorMap,
        })}
      </Row>
    );
  } else if (type === "link") {
    const linkClick = getLinkOnClick(value);

    const truncateAmount = column.truncate || 35;

    const finalValue = get(column, "linkText") || value;

    if (linkClick) {
      return (
        <Text
          data={{
            text: truncateText(finalValue, truncateAmount),
            onClick: linkClick,
            color: column.fontColor || colors.primary,
            fontStyle: column.bold ? "headingMd" : "bodyLg",
            cursor: "pointer",
            allowSelect: true,
          }}
        />
      );
    }
  }

  const truncateAmount = column.truncate || 25;

  const tooltipKey = snakeCase(`${column.id}-${cellIndex}-${value}`);

  // Plain text
  return (
    <>
      {value && value.length > truncateAmount && (
        <Tooltip
          anchorSelect={`.${tooltipKey}`}
          place="bottom"
          style={{ zIndex: 9999, maxWidth: "400px" }}
        >
          {truncateText(value, 600)}
        </Tooltip>
      )}

      <div className={tooltipKey}>
        <Text
          data={{
            allowSelect: true,
            text: truncateText(value, truncateAmount),
            color: column.fontColor || "var(--text-color)",
            fontStyle: column.bold ? "headingMd" : "bodyLg",
          }}
        />
      </div>
    </>
  );
};

const BooleanWrapper = styled.div`
  display: flex;
  background: ${(p) => (p.value ? "#B8E7D2" : "#F9D5D2")};
  border-radius: 20px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  overflow-y: auto;
`;

const NoContent = styled.div`
  padding: 15px;
  border-top: 1px solid var(--divider);
  color: var(--dark-grey);
`;

const ColumnContainerNew = styled.div`
  flex: 1 0 auto;
  min-width: fit-content;
`;

const TableContainer = styled.div`
  overflow-x: auto;
  flex: 1;
  display: flex;
  cursor: pointer;
  overflow-y: auto;
  height: ${(p) => p.height};
`;

const CellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.alignEnd ? "flex-end" : "flex-start")};
  height: ${(p) => p.height};
  flex: 1;
  min-width: fit-content;
  padding: 0 ${spacing.s5} 0 ${spacing.s5};
  :not(:last-child) {
    border-bottom: 1px ${(p) => p.borderStyle} var(--divider);
  }
  background: ${(p) => p.background};
`;
