import { Button, Menu, Modal } from ".";
import { get, startCase } from "lodash";
import { useRef, useState } from "react";

import { AdminForm } from "app/adminApp/components";
import MarkdownEditor from "./MarkdownEditor";
import { rActiveStepIndex } from "app/utils/recoil";
import { useRecoilValue } from "recoil";
import useTool from "app/utils/useTool";

const MarkdownPopupEditor = ({ data }) => {
  const [showEditor, setShowEditor] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const value = get(data, "value", "Edit content here...");
  const [stepId, setStepId] = useState(null);
  const [fieldKey, setFieldKey] = useState(null);

  const { tool } = useTool();
  const steps = get(tool, "steps", []);
  const activeStepIndex = useRecoilValue(rActiveStepIndex);
  const markdownEditorRef = useRef(null);

  const previousSteps = activeStepIndex
    ? steps.slice(0, activeStepIndex)
    : steps;
  const filteredSteps = previousSteps.filter((s) => s.type !== "education");

  let fields = [
    {
      label: "Step",
      componentId: "Select",
      onChange: (k, v) => setStepId(v),
      value: stepId,
      options: filteredSteps.map((step) => ({
        label: step.label,
        value: step.id,
        icon: get(
          {
            input: "FiEdit",
            output: "FiSend",
            education: "FiBook",
          },
          get(step, "type", "input")
        ),
      })),
    },
  ];

  const selectedStep = filteredSteps.find((s) => s.id === stepId);
  const selectedStepIsInput = get(selectedStep, "type", null) === "input";

  if (selectedStepIsInput) {
    fields.push({
      label: "Field",
      value: fieldKey,
      componentId: "Select",
      onChange: (k, v) => setFieldKey(v),
      options: get(selectedStep, "inputs", []).map((f) => ({
        label: startCase(f.key),
        value: f.key,
      })),
    });
  }

  const injectVariable = () => {
    const variable =
      selectedStepIsInput && fieldKey
        ? `{{ ${stepId}.${fieldKey} }}`
        : `{{ ${stepId} }}`;
    markdownEditorRef.current?.insertVariable(variable);
    setAnchorEl(null); // Close the menu after insertion
  };

  return (
    <>
      <Menu
        label="Insert Variable"
        anchorElement={anchorEl}
        hide={() => setAnchorEl(null)}
        padding="15px"
        minWidth="200px"
        width="400px"
      >
        <AdminForm
          fields={fields}
          sectionPadding={"0px"}
          submitText="Insert Variable"
          submit={injectVariable}
        />
      </Menu>
      <Button
        data={{
          ...data,
          text: "Edit Content",
          type: "basic",
          onClick: () => setShowEditor(true),
        }}
      />
      {showEditor && (
        <Modal
          zIndex={1}
          minWidth="800px"
          header={{
            title: "Edit Content",
            buttons: [
              {
                text: "Insert Variable",
                icon: "FiCode",
                size: "small",
                type: "basic",
                onClick: (e) => setAnchorEl(e.currentTarget),
              },
            ],
          }}
          hide={() => setShowEditor(false)}
        >
          <MarkdownEditor
            ref={markdownEditorRef}
            data={{ value, onChange: data.onChange }}
          />
        </Modal>
      )}
    </>
  );
};

export default MarkdownPopupEditor;
