import { Button, ColorPicker, Icon } from "app/components";

import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";

const ColorThemeEditor = ({ data }) => {
  const colors = get(data, "value", []);

  const addColor = () => {
    data.onChange([...colors, "#000000"]);
  };

  const update = (value, index) => {
    data.onChange(
      colors.map((c, ci) => {
        if (index === ci) {
          return value;
        }
        return c;
      })
    );
  };

  return (
    <Container>
      <ColorsRow>
        {colors.map((c, i) => (
          <ColorContainer>
            <ColorPicker
              data={{
                value: c,
                onChange: (v) => update(v, i),
              }}
            />
            <Icon
              data={{
                color: "grey",
                icon: "FiTrash",
                size: 16,
                onClick: () => {
                  data.onChange(colors.filter((_, ci) => ci !== i));
                },
              }}
            />
          </ColorContainer>
        ))}
      </ColorsRow>
      <Button
        data={{
          text: "Add Color",
          size: "small",
          icon: "FiPlus",
          type: "basic",
          onClick: addColor,
        }}
      />
    </Container>
  );
};

export default ColorThemeEditor;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ColorsRow = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
`;

const ColorContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  border: 1px solid ${colors.pitchBorder};
  border-radius: 10px;
  padding: 4px;
`;
