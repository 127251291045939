import { Menu, Text } from ".";
import { colors, fontObject } from "app/utils/theme";

import { get } from "lodash";
import { getPixels } from "app/utils/utils";
import styled from "styled-components";
import { useState } from "react";

export const commonColors = [
  "#ca7580",
  "#ebc17d",
  "#84ce9b",
  "#669ac5",
  "#a681d7",
  "#a6a6a6",
];

export const otherColors = [
  "#c4b865",
  "#f0cf5f",
  "#de7c55",
  "#e9b4b1",
  "#df837d",
  "#9f4556",
  "#c13d71",
  "#dc6ebc",
  "#e2a8e9",
  "#6d4fc3",
  "#6e4282",
  "#542eec",
  "#5759d0",
  "#6782bb",
  "#7298ee",
  "#88c7c4",
  "#91c8f5",
  "#8aacc6",
  "#a1acba",
  "#757575",
  "#7a7a7a",
  "#74574b",
  "#cc8db8",
  "#b5a790",
  "#b9e0f2",
  "#bd9688",
  "#456bd1",
  "#668c95",
  "#b7aaee",
  "#b0bde1",
  "#9c99b4",
  "#776564",
];

const ColorSelect = ({ data }) => {
  const { onChange } = data;

  const fontStyle = get(data, "fontStyle", "bodyMd");
  const styleObject = get(fontObject, fontStyle);
  const validTypes = ["text", "number", "password", "email"];

  // HANDLE OBJECT OR STRING VALUE
  let value = get(data, "value") || get(data, "defaultValue") || "";

  const [anchorEl, setAnchorEl] = useState(null);

  let backgroundColor = data.backgroundColor || data.background || "white";

  return (
    <>
      <Menu
        anchorElement={anchorEl}
        hide={() => setAnchorEl(null)}
        padding="15px"
        minWidth="200px"
        width="400px"
      >
        <Text
          data={{ text: "Common Colors", fontWeight: 600, margin: "0 0 5px 0" }}
        />
        <ColorsGrid>
          {commonColors.map((c) => (
            <ColorPreview
              color={c}
              onClick={() => onChange(c)}
              active={value === c}
            />
          ))}
        </ColorsGrid>

        <Text
          data={{
            text: "Other Colors",
            fontWeight: 600,
            margin: "15px 0 5px 0",
          }}
        />
        <ColorsGrid>
          {otherColors.map((c) => (
            <ColorPreview
              color={c}
              onClick={() => onChange(c)}
              active={value === c}
            />
          ))}
        </ColorsGrid>
      </Menu>
      <Container
        {...styleObject}
        {...data}
        height={getPixels(data.height)}
        type={validTypes.includes(data.type) ? data.type : "text"}
        background={backgroundColor}
        width={data.width || "100%"}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <ColorPreview color={value} active={!value} />
      </Container>
    </>
  );
};

export default ColorSelect;

const ColorsGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

const ColorPreview = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: ${(p) => p.color};
  cursor: pointer;
  border: 2px solid ${(p) => (p.active ? "black" : "transparent")};
  &:hover {
    filter: brightness(1.1);
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(p) => p.padding || "10px"};
  border: ${(p) => p.border || `1px solid ${colors.pitchBorder}`};
  border-width: ${(p) => p.borderWidth};
  border-radius: ${(p) => getPixels(p.borderRadius || 10)};
  background: ${(p) => p.background};
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  text-align: left;
  min-width: 50px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;
