import Tool from "app/tool";
import ToolConfig from "app/toolConfig";
import ToolEditorHeader from "./ToolEditorHeader";
import { get } from "lodash";
import { getPixels } from "app/utils/utils";
import { rScreenPreviewSize } from "app/utils/recoil";
import styled from "styled-components";
import useContainerDimensions from "app/utils/useContainerDimensions";
import { useRecoilValue } from "recoil";
import { useRef } from "react";

const ToolEditor = () => {
  const screenPreviewSize = useRecoilValue(rScreenPreviewSize);

  const previewRef = useRef();

  // The actual available width of the preview section
  const { width } = useContainerDimensions(previewRef, screenPreviewSize);

  const sizeMap = {
    MOBILE: 400,
    TABLET: 800,
    // DESKTOP: "100%",
  };

  let previewWidth = get(sizeMap, screenPreviewSize, width);
  // If the width is less than the preview width, set the preview width to the width
  if (width < previewWidth) {
    previewWidth = width;
  }

  const backgroundColor = "white";

  return (
    <>
      <ToolEditorHeader />
      <Body backgroundColor={backgroundColor}>
        <ConfigContainer>
          <PanelContent>
            <ToolConfig />
          </PanelContent>
        </ConfigContainer>
        <PreviewContent backgroundColor={backgroundColor} ref={previewRef}>
          <NewContainer
            width={previewWidth}
            liveAppTheme={"light"}
            showBorder={screenPreviewSize !== "DESKTOP"}
          >
            <Tool previewWidth={previewWidth} />
          </NewContainer>
        </PreviewContent>
      </Body>
    </>
  );
};

export default ToolEditor;

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const ConfigContainer = styled.div`
  min-width: 380px;
  max-width: 380px;
  overflow-y: auto;
  border-right: 1px solid var(--divider);
  background: var(--card-background);
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
`;

const PanelContent = styled.div`
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
  width: 100%;
`;

const PreviewContent = styled.div`
  position: relative;
  margin: 0 auto;
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  width: calc(100% - 380px);
  border-radius: 10px;
  overflow: hidden;
`;

const NewContainer = styled.div`
  position: relative;
  background: var(--background);
  width: ${(p) => getPixels(p.width)};
  ${(p) =>
    p.showBorder &&
    `border-left: 1px solid var(--divider); border-right: 1px solid var(--divider);`}
  margin: 0 auto;
  height: 100%;
  overflow-y: auto;
`;
