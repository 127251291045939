import { ContentRow, MainContent } from "./Browse";
import { FullScreenLoader, Label, Spinner } from "app/components";
import { useEffect, useState } from "react";

import AuthSidebar from "./AuthSidebar";
import CardGrid from "app/adminApp/components/CardGrid";
import { EmptyStateCard } from "app/components/EmptyStateCard";
import PublicWrapper from "app/public/PublicWrapper";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { rUser } from "app/utils/recoil";
import { successNotification } from "app/utils/Notification";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const History = () => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [sessions, setSessions] = useState([]);
  const user = useRecoilValue(rUser);

  useEffect(() => {
    setIsFetching(true);
    apiRequest.get("/sessions/").then((res) => {
      setSessions(res.data);
      setIsFetching(false);
    });
  }, []);

  const duplicateSession = (sessionId) => {
    apiRequest
      .post(`/duplicate_session/`, {
        id: sessionId,
      })
      .then((res) => {
        const newSession = get(res, "data", null);
        setSessions([newSession, ...sessions]);
        successNotification("Session duplicated");
      });
  };

  const deleteSession = (sessionId) => {
    apiRequest.delete(`/sessions/`, { data: { id: sessionId } }).then(() => {
      setSessions(sessions.filter((s) => s.id !== sessionId));
      successNotification("Session deleted");
    });
  };

  // TODO - Add 'skeleton loader' while fetching
  if (isFetching) {
    return <FullScreenLoader />;
  }

  return (
    <PublicWrapper>
      <ContentRow>
        {user && <AuthSidebar />}
        <MainContent>
          <Label style={{ marginBottom: "30px" }}>Sessions</Label>
          {isFetching ? (
            <Spinner color="var(--text-color)" />
          ) : (
            <>
              {sessions.length === 0 && (
                <EmptyStateCard
                  title="No history found"
                  description="Start using tools to see your history here"
                />
              )}
              <CardGrid
                hasImage
                items={sessions.map((s) => ({
                  title: s.name,
                  image: s.image,
                  noResultsName: "sessions",
                  progress_percent: s.progress_percent,
                  description: `${s.progress_percent}% complete`,
                  options: [
                    {
                      label: "Duplicate Session",
                      icon: "FiCopy",
                      onClick: () => duplicateSession(s.id),
                    },
                    {
                      label: "Delete Session",
                      icon: "FiTrash",
                      onClick: () => deleteSession(s.id),
                    },
                  ],
                  onClick: () => {
                    if (s.complete) {
                      navigate(`/session/${s.uuid}`);
                    } else {
                      navigate(`/tool/${s.tool_id}/?sessionId=${s.uuid}`);
                    }
                  },
                }))}
                itemsPerPage={12}
              />
            </>
          )}
        </MainContent>
      </ContentRow>
    </PublicWrapper>
  );
};

export default History;
