import { useLocation, useNavigate } from "react-router-dom";

import General from "./General";
import Password from "./Password";
import { SettingsWrapper } from "./components/SettingsWrapper";
import { getUrlParameter } from "app/utils/utils";

const Settings = () => {
  const location = useLocation();
  const tab = getUrlParameter("tab", location) || "general";
  const navigate = useNavigate();

  let sections = [
    {
      id: "general",
      label: "General",
      component: General,
      icon: "FiSettings",
    },
    // {
    //   id: "branding",
    //   label: "Branding",
    //   component: Branding,
    //   icon: "FiTag",
    // },
    {
      id: "password",
      label: "Password",
      component: Password,
      icon: "FiKey",
    },
  ].map((s) => ({
    ...s,
    onClick: () => navigate(`/admin/settings?tab=${s.id}`),
  }));

  const currentSection = sections.find((section) => section.id === tab);
  const Component = currentSection.component;

  return (
    <SettingsWrapper sections={sections} tab={tab} label="Settings">
      <Component />
    </SettingsWrapper>
  );
};

export default Settings;
