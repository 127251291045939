import { Button, Icon, Modal, Row, Text } from "app/components";
import { rApp, rConfirmationModalData, rTranslations } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import React from "react";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { isAdmin } from "app/utils/utils";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
`;

const ConfirmationModal = () => {
  const [isSaving, isSavingSet] = React.useState(false);

  const [confirmationModalData, setConfirmationModalData] = useRecoilState(
    rConfirmationModalData
  );

  const translations = useRecoilValue(rTranslations);

  const activeApp = useRecoilValue(rApp);

  const styling = get(activeApp, "styling", {});
  const blockBorderRadius = isAdmin
    ? "12px"
    : get(styling, "blockBorderRadius");

  if (confirmationModalData) {
    const { title, text, confirm, hide, confirmText, cancelText } =
      confirmationModalData;

    const hideModal = (e) => {
      e.stopPropagation();
      hide && hide();
      setConfirmationModalData(null);
    };

    return (
      <Modal
        background={"var(--background)"}
        borderRadius={blockBorderRadius}
        hide={(e) => {
          hideModal(e);
        }}
        header={{ title }}
      >
        <Wrapper>
          <Icon
            data={{
              icon: "FiAlertCircle",
              color: "#f79e44",
              size: 50,
            }}
          />
          <Text
            data={{
              text,
              margin: "20px 0 30px 0",
              textAlign: "center",
              fontStyle: "bodyMd",
            }}
          />
          <Row gap="15px">
            {confirm && (
              <Button
                data={{
                  text:
                    confirmText ||
                    get(translations, "confirmationConfirm", "Confirm"),
                  backgroundColor: isAdmin
                    ? colors.primary
                    : get(activeApp, "primary_color"),
                  onClick: (e) => {
                    e.stopPropagation();
                    confirm(isSavingSet);
                    setConfirmationModalData(null);
                  },
                  isFetching: isSaving,
                }}
              />
            )}
            <Button
              data={{
                text:
                  cancelText ||
                  get(translations, "confirmationCancel", "Cancel"),
                type: "basic",
                onClick: (e) => hideModal(e),
              }}
            />
          </Row>
        </Wrapper>
      </Modal>
    );
  }

  return <></>;
};

export default ConfirmationModal;
