import { Hint, Icon, Row, Text } from "app/components";
import { colors, spacing } from "app/utils/theme";

import styled from "styled-components";

const NavTabs = styled.div`
  display: flex;
  padding: ${(p) => p.padding || "0px"};
  margin: ${(p) => p.margin || "0px"};
  gap: ${spacing.s5};
  border-bottom: ${(p) => p.borderBottom};
`;

const NavTab = styled.div`
  padding-bottom: 10px;
  border-bottom: 3px solid ${(p) => (p.active ? colors.primary : "transparent")};
  opacity: ${(p) => (p.disabled ? "0.5" : p.active ? "1.0" : "0.6")};
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
`;

const Tabs = ({ data }) => {
  const { padding, margin, tabs, borderBottom } = data;

  return (
    <NavTabs padding={padding} margin={margin} borderBottom={borderBottom}>
      {tabs
        .filter((tab) => !tab.hidden)
        .map((tab, index) => {
          return (
            <NavTab
              key={index}
              active={tab.active}
              disabled={tab.disabled}
              onClick={tab.disabled ? null : tab.onClick}
            >
              <Row alignItems="center">
                {tab.icon && (
                  <Icon
                    data={{
                      size: "20px",
                      icon: tab.icon,
                      hover: true,
                      margin: "0 10px 0 0",
                      color: data.color || colors.primary,
                    }}
                  />
                )}

                <Text
                  data={{
                    text: tab.label,
                    fontStyle: data.fontStyle || "headingMd",
                    color: data.color || "var(--dark-grey)",
                    cursor: "pointer",
                  }}
                />

                {tab.hint && (
                  <Hint data={{ ...tab.hint, margin: "5px 0 0 2px" }} />
                )}
              </Row>
            </NavTab>
          );
        })}
    </NavTabs>
  );
};

export default Tabs;
