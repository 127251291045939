import { rApps, rUser } from "app/utils/recoil";
import { useEffect, useState } from "react";

import { Button } from "app/components";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { errorNotification } from "app/utils/Notification";
import { get } from "lodash";
import logo from "assets/scaale-logo-blue-white.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

const Onboarding = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [onboardingData, setOnboardingData] = useState({});

  const name = get(onboardingData, "name", "");
  const company = get(onboardingData, "website", "");
  const source = get(onboardingData, "source", "");
  const project = get(onboardingData, "project", "");

  const [apps, setApps] = useRecoilState(rApps);

  const [user, setUser] = useRecoilState(rUser);

  const steps = [
    {
      key: "name",
      question: "What is your full name?",
      placeholder: "Jane Smith",
    },
    {
      key: "website",
      question: `Hi ${name}! What is your company website?`,
      placeholder: "yourcompany.com",
    },
    {
      key: "source",
      question: "How did you hear about Scaale?",
      options: ["Facebook Group", "LinkedIn", "Friend", "X/Twitter", "AppSumo"],
    },
    {
      key: "project",
      question: "What are you looking to build with Scaale?",
      placeholder: "I'm looking to build a...",
    },
  ];

  const currentStep = steps[currentStepIndex];

  const currentQuestion = currentStep.question;
  const currentStepValue = onboardingData[currentStep.key];

  const [isFetching, setIsFetching] = useState(false);

  const navigate = useNavigate();

  // Handlers to update state
  const onChange = (value) => {
    setOnboardingData({ ...onboardingData, [currentStep.key]: value });
  };

  const handleNext = async () => {
    if (currentStepIndex === steps.length - 1) {
      setIsFetching(true);
      const res = await apiRequest.post("/onboarding_survey/", onboardingData);
      const userData = get(res, "data", {});

      const subdomain = get(userData, "subdomain", "");

      setApps([{ ...get(apps, 0), subdomain }]);

      setIsFetching(false);

      setUser({
        ...user,
        ...userData,
      });
      navigate("/tools");
    } else {
      // Apply validation
      if (currentStep.key === "name" && !name) {
        errorNotification("Please enter your name");
        return;
      }

      if (currentStep.key === "website" && !company) {
        errorNotification("Please enter your company website");
        return;
      }

      if (currentStep.key === "source" && !source) {
        errorNotification("Please make a valid selection");
        return;
      }

      if (currentStep.key === "project" && !project) {
        errorNotification("Please describe your project");
        return;
      }

      setCurrentStepIndex(currentStepIndex + 1);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentStepIndex, onboardingData]);

  return (
    <Container>
      <Logo src={logo} />

      <Content>
        <Question>{currentQuestion}</Question>
        <RenderInput
          key={currentStep.key}
          step={currentStep}
          onChange={onChange}
          value={currentStepValue}
        />
      </Content>

      <ButtonRow>
        <div></div>
        <Button
          data={{
            text: "Continue",
            icon: "FiArrowRight",
            size: "large",
            flippedIcon: true,
            onClick: handleNext,
            isFetching,
          }}
        />
      </ButtonRow>
    </Container>
  );
};

export default Onboarding;

const RenderInput = ({ step, onChange, value }) => {
  switch (step.key) {
    case "source":
      const options = get(step, "options", []);

      const otherValue = options.includes(value) ? "" : value;

      return (
        <OptionsContainer>
          {options.map((option) => (
            <Option
              key={option}
              active={value === option}
              onClick={() => onChange(option)}
            >
              {option}
            </Option>
          ))}
          <OtherInput
            value={otherValue}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Other (type here)"
          />
        </OptionsContainer>
      );
    default:
      let fontSize = "80px";
      if (get(value, "length", 0) > 30) {
        fontSize = "60px";
      }
      if (get(value, "length", 0) > 45) {
        fontSize = "40px";
      }
      return (
        <StyledTextArea
          fontSize={fontSize}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={step.placeholder}
          autoFocus
        />
      );
  }
};

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const Option = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: var(--text-color);
  cursor: pointer;
  width: fit-content;
  border: 1px solid var(--text-color);
  border-radius: 30px;
  padding: 15px;
  ${({ active }) =>
    active &&
    `
      background: #292929;
      color: white;
    `}
`;

const OtherInput = styled.input`
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: var(--text-color);
  border: 1px solid var(--text-color);
  border-radius: 30px;
  padding: 15px;
  background: transparent;
`;

// Styled-components
const Container = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  background: var(--background);
  padding: 40px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
  margin-left: 100px;
  @media screen and (max-width: 800px) {
    margin-left: 0px;
    width: 90%;
  }
`;

const Logo = styled.img`
  height: 40px;
`;

const Question = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--text-color);
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  font-family: "Inter", sans-serif;
  font-size: ${(p) => p.fontSize || "80px"};
  @media screen and (max-width: 800px) {
    font-size: 40px;
  }
  padding: 0px;
  border: 0px;
  margin-top: 10px;
  outline: none;
  background-color: transparent;
  width: 100%;
  resize: none;
  color: var(--text-color);
  ::placeholder {
    color: var(--light-grey);
  }
`;
