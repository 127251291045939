import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { getPixels } from "app/utils/utils";

const SkeletonLoader = ({
  height,
  width,
  radius = null,
  className = null,
  extra = null,
  green = null,
  light = null,
  margin = "",
  widthRange = null,
  heightRange = null,
  calcWidth = null,
}) => {
  let [currentWidth, setCurrentWidth] = useState(width);
  let [currentHeight, setCurrentHeight] = useState(height);

  useEffect(() => {
    // if widthRange is passed, generate a random width between the range
    if (widthRange) {
      const [min, max] = widthRange;
      width = getPixels(Math.floor(Math.random() * (max - min + 1)) + min);
    }

    if (heightRange) {
      const [min, max] = heightRange;
      height = getPixels(Math.floor(Math.random() * (max - min + 1)) + min);
    }

    setCurrentWidth(width);
    setCurrentHeight(height);
  }, []);

  return (
    <Skeleton
      className={className}
      radius={radius}
      height={currentHeight}
      width={currentWidth}
      extra={extra}
      green={green}
      light={light}
      margin={margin}
      calcWidth={calcWidth}
    />
  );
};

export default SkeletonLoader;

const Skeleton = styled.div`
  margin: ${(p) => p.margin};
  max-width: 100%;
  min-width: ${(p) => p.width || "100%"};
  min-height: ${(p) => p.height || "18px"};
  width: ${(p) => p.width || "100%"};
  height: ${(p) => p.height || "18px"};
  border-radius: ${(p) => p.radius || "4px"};
  ${(p) => p.calcWidth && `width: ${p.calcWidth};`}
  background: ${(p) =>
    p.light ? "rgba(255,255,255,0.30)" : "rgba(0,0,0,0.07)"};
  background: ${(props) =>
    props.light
      ? css`linear-gradient(-90deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0.30) 50%, rgba(255,255,255,0.15) 100%)`
      : `linear-gradient(-90deg, rgba(0,0,0,0.04) 0%, rgba(0,0,0,0.07) 50%, rgba(0,0,0,0.04) 100%)`};

  ${(p) =>
    p.border && (p.light ? "rgba(255,255,255,0.40)" : "rgba(0,0,0,0.40)")};

  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;
